import React, { useState, useEffect } from "react";
import "./style.scss";
import "../../../../pages/Reports/style.scss";
import Images from "../../../../assets/img/ImgConstants";
import CustomerImage from "../../../../assets/img/customer-image.svg";
import {
  Box,
  Chip,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  Skeleton,
} from "@mui/material";
import { getIndividualsTabData } from "../../../../redux/actions/insights";
import { useDispatch } from "react-redux";

const statuses = [
  [Images.RED_ELLIPSE, "Offsite"],
  [Images.YELLOW_ELLIPSE, "Likely Onsite"],
  [Images.GREEN_ELLIPSE, "Onsite"],
];

const personaChipClass = (persona) => {
  switch (persona) {
    case "Luxury":
      return "luxuryOrange";
    case "Needs based":
      return "needBlue";
    case "Exclusive":
      return "exclusiveRed";
    case "Discount driven":
      return "discountPurple";
    case "Trend setter":
      return "trendYellow";
    case "Research Oriented":
      return "researchGreen";
    default:
      return "";
  }
};

const CustomersTable = (props) => {
  const { startDate, endDate, setCustomerId, individualsListing, isLoading } =
    props;
  const ItemsPerPage = 4;
  const totalPages = Math.ceil(individualsListing?.length / ItemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(isLoading);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    setLoading(isLoading);
  }, [startDate, endDate, isLoading]);

  const handleRowClick = (row) => {
    setCustomerId(row.id); // customer id to be passed below, can directly pass row.id

    dispatch(
      getIndividualsTabData({
        customerId:
          "4EAF598A99E74842B0AE3BAD136C5501635F725F73636F747440796D61696C2E636F6D",
        startDate: startDate,
        endDate: endDate,
      })
    );
  };

  const handleSortStatus = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const getSortedAndPaginatedRows = () => {
    if (!Array.isArray(individualsListing)) {
      return [];
    }
    const sortedRows = [...individualsListing].sort((a, b) => {
      if (sortDirection === "asc") {
        return a.status - b.status;
      } else {
        return b.status - a.status;
      }
    });

    const startIndex = (currentPage - 1) * ItemsPerPage;
    const endIndex = startIndex + ItemsPerPage;
    const paginatedRows = sortedRows.slice(startIndex, endIndex);

    return paginatedRows;
  };

  const skeletonRows = () => {
    let results = [];
    for (let i = 0; i < 4; i++) {
      results.push(
        <TableRow key={i.id}>
          <TableCell>
            <Skeleton className="skeletonCell" active />
          </TableCell>
          <TableCell>
            <Skeleton className="skeletonCell" active />
          </TableCell>
          <TableCell>
            <Skeleton className="skeletonCell" active />
          </TableCell>
          <TableCell>
            <Skeleton className="skeletonCell" active />
          </TableCell>
          <TableCell>
            <Skeleton className="skeletonCell" active />
          </TableCell>
          <TableCell>
            <Skeleton className="skeletonCell" active />
          </TableCell>
          <TableCell>
            <Skeleton className="skeletonCell" active />
          </TableCell>
          <TableCell>{Images.EYE_ICON}</TableCell>
        </TableRow>
      );
    }

    return results;
  };

  return (
    <Box className="individualCustomerCard">
      <Box className="customerTableUpperSection">
        <Typography variant="h6" className="blue">
          Customers
        </Typography>
        <Chip
          label="200 users"
          variant="outlined"
          size="small"
          className="chip"
        />
      </Box>
      <Box sx={{ overflow: "auto" }}>
        <Box
          sx={{
            width: "100%",
            display: "table",
            tableLayout: "fixed",
            overflowX: loading && "clip",
          }}
        >
          <Table className="tableDesign">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell onClick={handleSortStatus}>
                  Status {Images.ARROW_DOWN_ICON}
                </TableCell>
                <TableCell>Persona</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Activity</TableCell>
                <TableCell>Transaction</TableCell>
                <TableCell>View</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading || individualsListing.length === 0
                ? skeletonRows()
                : getSortedAndPaginatedRows()?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        <img
                          src={CustomerImage}
                          alt={row.name}
                          style={{ marginRight: "16px" }}
                        />
                        {row.name}
                      </TableCell>
                      <TableCell>
                        <Chip
                          avatar={<Box>{statuses[row.status][0]}</Box>}
                          label={statuses[row.status][1]}
                          variant="outlined"
                          size="small"
                          className="statusChip"
                        />
                      </TableCell>
                      <TableCell>
                        {row.persona?.map((singlePersona) => (
                          <Chip
                            label={singlePersona}
                            variant="filled"
                            size="small"
                            className={`personaChip ${personaChipClass(singlePersona)}`}
                          />
                        ))}
                      </TableCell>
                      <TableCell>{row.rating}%</TableCell>
                      <TableCell>{row.activity}</TableCell>
                      <TableCell>{row.transaction}</TableCell>
                      <TableCell onClick={() => handleRowClick(row)}>
                        {Images.EYE_ICON}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, newPage) => setCurrentPage(newPage)}
      />
    </Box>
  );
};

export default CustomersTable;
