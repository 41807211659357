import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import BioCard from "../BioCard";
import FeedCard from "../FeedCard";
import RecommendationsWrapper from "../CommunicationCardWrapper";
import { Box, Grid, Typography, Chip } from "@mui/material";
import { Carousel } from "antd";
import dealing from "../../../assets/img/dealing.png";
import wheel from "../../../assets/img/wheel.png";
import { useDispatch, useSelector } from "react-redux";
import Recommendations from "../RecommendationsCard";
import "./style.scss";

const CustomerDetaiilTab = () => {
  const [recommendData, setRecommendData] = useState();
  const [segmentsData, setSegmentsData] = useState();
  const dispatch = useDispatch();
  const { insightsData, isLoading } = useSelector((state) => state.insights);
  const { recommendationData } = useSelector((state) => state.dashboard);
 
  let description =
    "26 step hand wash service covering all important areas of the vehicle. Thorough vacuum and compressed air blow out of the entire vehicle.";
  let cardImage = wheel;

  useEffect(() => {
    if (!isLoading) {
      setSegmentsData(insightsData);
    }
  }, [insightsData]);
  useEffect(() => {
    if (recommendationData) {
      setRecommendData(recommendationData);
    }
  }, [recommendationData]);

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });

  return (
    <Box style={{ marginBottom: "40px" }}>
      <Grid container spacing={3}>
        <Grid item xl={8} lg={8} md={8} xs={12}>
          <div style={{ height: "100%", maxHeight: "700px" }}>
            <BioCard
              name={insightsData?.segmentBio.name}
              image={insightsData?.segmentBio.img}
              rating={insightsData?.segmentBio.rating}
              isLoading={isLoading}
            />
          </div>
        </Grid>
        {!isMobile && (
          <Grid item xl={4} lg={4} md={4} xs={8}>
            <div style={{ height: "100%", maxHeight: "700px" }}>
              <FeedCard
                name={insightsData?.segmentBio.name}
                image={insightsData?.segmentBio.img}
                rating={insightsData?.segmentBio.rating}
                isLoading={isLoading}
              />
            </div>
          </Grid>
        )}
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <div>
            <Box className={!isMobile ? "segmentBio" : ""}>
              <Grid
                container
                className="bioUpperSection" style={{ backgroundColor: !isMobile ? "white" : "none", padding: "2%" }}
              >
                <Grid xs={12}>
                  <Typography variant="body1" className="blue bold-700">
                    <span>Recommendations </span>
                  </Typography>
                </Grid>
              </Grid>
              {recommendData?.statusCode != 400 ?
                <Grid item sm={12} xs={12} md={12} position={"relative"}>

                  <Box
                    className="customer-insights sliderBox customeCarousel"
                    // style={{ marginBottom: "35px", marginLeft: "0px",  height: '500px',
                    // maxHeight: 600}}
                    style={{ marginBottom: "15px" }}
                  >
                    <Carousel
                      infinite={false}
                      slidesToShow={isMobile ? 2 : isTablet ? 3 : 4}
                      slidesToScroll={isMobile ? 2 : isTablet ? 2 : 3}
                      dots={true}
                      arrows={false}
                    >
                      {recommendData?.statusCode != 400 && recommendData?.map((card, index) => (
                        <Recommendations
                          name={card.recommendation}
                          image={cardImage}
                          description={description}
                          isLoading={recommendData?.length > 0 ? true : false}
                          price={card.price}
                        />
                      ))}
                      {isMobile && <div></div>}
                    </Carousel>
                  </Box>
                  {(!isMobile && recommendData?.length > 0) && <RecommendationsWrapper />}

                </Grid> :

                <div style={{ justifyContent: "center", marginTop: "5%", display: "flex" }}>
                  <Chip
                    label={"No Data found!"}
                    variant="filled"
                    className="segmentBioBlueBadge badge"
                    style={{
                      fontSize: "small",
                      width: "-webkit-fill-available",
                      justifyContent: "center",
                      paddingLeft: "4px",
                      width:"30%"
                    }}

                  />

                </div>}
            </Box>
            {isMobile && <RecommendationsWrapper />}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerDetaiilTab;
