import React from "react";
import "./index.scss";

const Location = () => {
  return (
    <div className="locationPage">
      <h4>Location</h4>
    </div>
  );
};

export default Location;
