import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useFormik } from "formik";
import * as yup from "yup";
import { Typography, Box, TextField, Button } from "@mui/material";
import GoogleLogo from "../../../assets/img/google-icon.svg";
import MicrosoftLogo from "../../../assets/img/microsoft-icon.svg";
import "./style.scss";
import { sendCode } from "../../../redux/actions/auth";

const ForgotPassword = (props) => {
  const {
    handleForgotPassword,
    email,
    error,
    loading,
    handleGoogleSignIn,
    handleMicrosoftSignIn,
    setStep,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(sendCode(values.email));
    },
  });

  useEffect(() => {
    if (email != null && !loading && error === null) {
      handleForgotPassword();
    }
  }, [email, loading, error]);

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <Box className="forgotPassword">
      <Typography variant="body1" className="formTitle">
        Forgot Password
      </Typography>
      <Typography variant="body1" className="formHead">
        Enter email address
      </Typography>
      <TextField
        placeholder="name@company.com"
        variant="outlined"
        name="email"
        sx={{ marginBottom: 1 }}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />

      <Typography
        variant="body1"
        className="subText"
        onClick={handleBack}
        sx={{ cursor: "pointer" }}
      >
        Back to sign in
      </Typography>

      <Button
        variant="contained"
        className="forgotPasswordButton"
        onClick={formik.handleSubmit}
      >
        Send
      </Button>

      <Typography variant="body1" className="subText">
        Or continue with
      </Typography>
      <Box className="ssoButtons">
        <Button onClick={handleGoogleSignIn}>
          <img src={GoogleLogo} alt="Google Logo" />
        </Button>
        <Button onClick={handleMicrosoftSignIn}>
          <img src={MicrosoftLogo} alt="Microsoft Logo" />
        </Button>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
