import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Typography,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleLogo from "../../../assets/img/google-icon.svg";
import MicrosoftLogo from "../../../assets/img/microsoft-icon.svg";
import QuickbooksLogo from "../../../assets/img/quickbooks-logo.svg";
import "./style.scss";
import { login } from "../../../redux/actions/auth";


const LoginForm = (props) => {
  const { handleForgotPassword, error, loading, handleGoogleSignIn, handleMicrosoftSignIn } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberInfo, setRememberInfo] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };


  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoginClicked(false);
      dispatch(login({ email: values.email, password: values.password, setLoginClicked: setLoginClicked }));
    },
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignUp = () => {
    navigate("/create-account");
  };

  return (
    <Box className="loginForm">
      <Typography variant="body1" className="formHead">
        Email
      </Typography>
      <TextField
        placeholder="name@company.com"
        variant="outlined"
        name="email"
        sx={{ marginBottom: 1 }}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography variant="body1" className="formHead">
          Password
        </Typography>
        <Typography
          variant="body1"
          className="forgotPassword"
          onClick={handleForgotPassword}
        >
          Forgot Password
        </Typography>
      </Box>
      <TextField
        name="password"
        placeholder="Password"
        variant="outlined"
        sx={{ marginBottom: 1 }}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberInfo}
            onChange={(event) => setRememberInfo(event.target.checked)}
          />
        }
        label={
          <Typography variant="body1" className="formCondition">
            Remember information
          </Typography>
        }
      />
      {error !== "Invalid token specified" && error && loginClicked && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}

      <Button
        variant="contained"
        className="loginButton"
        onClick={formik.handleSubmit}
      >
        Login
      </Button>
      <Typography variant="body1" className="signUp" onClick={handleSignUp}>
        Don't have an account? <span className="link">Sign Up</span>
      </Typography>
      <Typography variant="body1" className="orLine">
        or continue with
      </Typography>
      <Box className="ssoButtons">
        <Button onClick={handleGoogleSignIn}>
          <img src={GoogleLogo} alt="Google Logo" />
        </Button>
        <Button
        onClick={handleMicrosoftSignIn}
        >
          <img src={MicrosoftLogo} alt="Microsoft Logo" />
        </Button>
        <Button
        // onClick={() => handleConnectQuickBooks()}
        >
          <img src={QuickbooksLogo} alt="Quickbooks Logo" />
        </Button>
      </Box>
    </Box>
  );
};

export default LoginForm;
