export const GET_SEGMENT_TAB_DATA = 'GET_SEGMENT_TAB_DATA';
export const GET_SEGMENT_TAB_DATA_SUCCESS = 'GET_SEGMENT_TAB_DATA_SUCCESS';
export const GET_SEGMENT_TAB_DATA_FAIL = 'GET_SEGMENT_TAB_DATA_FAIL';

export const GET_INDIVIDUALS_TAB_DATA = 'GET_INDIVIDUALS_TAB_DATA';
export const GET_INDIVIDUALS_TAB_DATA_SUCCESS = 'GET_INDIVIDUALS_TAB_DATA_SUCCESS';
export const GET_INDIVIDUALS_TAB_DATA_FAIL = 'GET_INDIVIDUALS_TAB_DATA_FAIL';

export const GET_INDIVIDUALS_TABLE_DATA = 'GET_INDIVIDUALS_TABLE_DATA';
export const GET_INDIVIDUALS_TABLE_DATA_SUCCESS = 'GET_INDIVIDUALS_TABLE_DATA_SUCCESS';
export const GET_INDIVIDUALS_TABLE_DATA_FAIL = 'GET_INDIVIDUALS_TABLE_DATA_FAIL';