import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Button, Divider, Chip } from "@mui/material";
import Images from "../../../../assets/img/ImgConstants";
import BulletIcon from "../../../../assets/img/marker-icon.svg";
import InfoIcon from "../../../../assets/img/information-icon.svg";
import { InfoIconList, PlanList } from "../../../../pages/Onboarding/constants";
import { createCheckoutSession } from "../../../../redux/actions/onboarding";

import "./style.scss";

const PlanCards = (props) => {
  const { isMonthly } = props;
  const dispatch = useDispatch();
  const lastPlan = PlanList.slice(-1)[0];
  const [updatedPlanList, setUpdatedPlanList] = useState(PlanList);
  const [plan, setPlan] = useState();
  const [isSelected, setIsSelected] = useState(updatedPlanList[1].id);
  const { onboarding, isLoading, error } = useSelector(
    (state) => state.onboarding
  );

  useEffect(() => {
    const updatedPlans = PlanList.map((plan) => {
      const planTitle = plan.title;
      const pricingType = isMonthly ? "Month" : "Year";
      const matchedPlan = onboarding.planInfo.list.find(
        (info) =>
          info.name.includes(planTitle) && info.name.includes(pricingType)
      );
      return {
        ...plan,
        price: matchedPlan ? matchedPlan.price / 100 : plan.price,
      };
    });
    setUpdatedPlanList(updatedPlans);
  }, [onboarding, isMonthly]);

  useEffect(() => {
    plan && handleNextButtonClick();
  }, [plan]);

  const handleNextButtonClick = () => {
    dispatch(
      createCheckoutSession({
        email: onboarding.account.email,
        plan: `${plan.title}/${isMonthly ? "Month" : "Year"}`,
      })
    );
  };

  return (
    <Box className="pricePlanCards">
      <Box className="pricePlanTopCards">
        {updatedPlanList.slice(0, -1).map((plan, index) => (
          <Box
            key={plan.id}
            className={`planCard ${plan.id === isSelected && `isSelected`}`}
            onClick={() => setIsSelected(plan.id)}
          >
            <Box className="cardUpperSection">
              {plan.title === "Business" && (
                <Chip
                  label="Most Popular Tier Pricing"
                  icon={Images.PRICE_PLAN_ICON}
                  className="cardChip"
                />
              )}
              <Typography variant="body2" className="cardHeading">
                {plan.title}
              </Typography>
              <Box className="cardPrice">
                <Typography variant="h4" className="price">
                  ${isMonthly ? plan.price : plan.price}
                </Typography>
                <Typography variant="subtitle2" className="pricePer">
                  {isMonthly ? `/month` : `/year`}
                </Typography>
              </Box>
              <Button variant="contained" onClick={() => setPlan(plan)}>
                Sign-up
              </Button>
              <Typography variant="body1" className="cardText">
                {plan.detail}
              </Typography>
            </Box>
            <Divider className="divider" />

            <Box className="cardLowerSection">
              {Array.isArray(plan.points) &&
                plan?.points?.map((point, i) => (
                  <Box className="bulletPoint">
                    <img src={BulletIcon} alt="bullet icon" />
                    <Typography variant="body1" className="cardText">
                      {point}
                    </Typography>
                    {InfoIconList.includes(point) && (
                      <img src={InfoIcon} alt="information icon" />
                    )}
                  </Box>
                ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        className="pricePlanBottomCard"
        onClick={() => setIsSelected(lastPlan.id)}
      >
        <Box className="cardTitle">
          <Typography variant="h5" className="cardHeading">
            {lastPlan.title}
          </Typography>
          <Typography variant="body2" className="cardPoint">
            {lastPlan.points}
          </Typography>
        </Box>
        <Typography variant="body1" className="cardText">
          {lastPlan.detail}
        </Typography>
        <Button variant="contained" onClick={() => setPlan(lastPlan)}>
          Contact us
        </Button>
      </Box>
    </Box>
  );
};

export default PlanCards;
