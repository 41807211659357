export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const cleanPhoneNumber = (phone_number) => {
  return phone_number
    .replace("(", "")
    .replace(")", "")
    .replace(" ", "")
    .replace("-", "");
};

export const formatPhoneNumber = (phone_number) => {
  if (!phone_number) return "";
  var x = phone_number.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  if (x != null) {
    return (phone_number = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : ""));
  } else return x;
};
