import { useMediaQuery } from "react-responsive";

import FooterLogoImg from "../../../assets/img/logo-footer.svg";
import "./style.scss";

const Footer = () => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const year = new Date().getFullYear();
  return (
    <>
      {isMobile || isTablet ? (
        <div className="footer mobile">
          <p>@{year}, DataBillity, Inc. All rights reserved.</p>
        </div>
      ) : (
        <div
          className={`footer ${isMobile ? " mobile" : ""}${
            isTablet ? " tablet" : ""
          }`}
        >
          <div className="footer-left">
            <img
              className="footer-logo"
              src={FooterLogoImg}
              alt="Footer Logo"
            />
            <p
              onClick={() => {
                const newWindow = window.open(
                  "https://www.databillity.com/eula",
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
              }}
            >
              End User Licensing Agreement
            </p>
            <p
              onClick={() => {
                const newWindow = window.open(
                  "https://www.databillity.com/product-privacy-policy",
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
              }}
            >
              Product Privacy Policy
            </p>
          </div>
          <div className="footer-right">
            <p>@{year}, DataBillity, Inc. All rights reserved.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
