import axios from "axios";
import { userPool } from "../../utils/aws-config";
import {
    GET_ALL_RECOMMENDATIONS,
    GET_ALL_RECOMMENDARTIONS_ERROR,
    GET_FEED_ID_LOADING,
    GET_ALL_FEEDS,
    GET_ALL_FEEDS_ERROR,
    GET_FEED_ID,
    GET_FEED_ID_ERROR,
    GET_ALL_PERSONAS,
    GET_ALL_PERSONAS_ERROR,
    UPDATE_PERSONAS,
    UPDATE_PERSONAS_ERROR,
    GET_USER_RATING,
    GET_USER_RATING_ERROR,
    CREATE_RATE_SUCCESS_DASHBOARD,
    CREATE_RATE_ERROR_DASHBOARD

} from "../constant/dashboard";


const getAxiosConfig = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession(function (err, session) {
      if (err) {
        reject(err);
      } else {
        const axiosConfig = {
          headers: {
            Authorization: `${session.accessToken.jwtToken}`,
            // Accept: "application/json",
            // "User-Agent": "Mozilla",
            // "User-Agent": "axios 0.21.1",
          },
        };
        // console.log(
        //   `this is the axios config = ${JSON.stringify(axiosConfig)}`
        // );
        resolve(axiosConfig);
      }
    });
  });
};

export const createRatingDashboard = (body) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `https://td7k6cgaz9.execute-api.us-east-1.amazonaws.com/customer/rating_review`,
      body,
      {
        headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true, 
  }}
    );
    dispatch({ type: CREATE_RATE_SUCCESS_DASHBOARD, payload: data });
  } catch (error) {
    dispatch({ type: CREATE_RATE_ERROR_DASHBOARD, payload: error });
  }
};
//getuserratewithid
export const getUserRatings = (userId) => async (dispatch) => {
  try {
   // await dispatch({ type: GET_USER_RATE });
    const { data } = await axios.get(
      `https://td7k6cgaz9.execute-api.us-east-1.amazonaws.com/customer/rating_review/${userId}`,
    );
    dispatch({ type: GET_USER_RATING, payload: data });
  } catch (error) {
    dispatch({ type: GET_USER_RATING_ERROR, payload: error });
  }
};


export const getAllRecommendations = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `https://him2r0x9xc.execute-api.us-east-1.amazonaws.com/recommendations?email=${id}`,
      {
        headers: {
           "x-api-key": "X97c1sUayGBCfivJgWYO6mvvWkmdtpl6IbVDOGlh",
           "Content-Type": "application/json",
           "Access-Control-Allow-Credentials": true, 
         },
        }
    
    );
    dispatch({ type: GET_ALL_RECOMMENDATIONS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_RECOMMENDARTIONS_ERROR, payload: error });
  }
};

export const getAllFeeds = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `https://xvufjkvibh.execute-api.us-east-1.amazonaws.com/api/customers`,
      {
        headers: {
           "x-api-key": "X97c1sUayGBCfivJgWYO6mvvWkmdtpl6IbVDOGlh",
           "Content-Type": "application/json",
           "Access-Control-Allow-Credentials": true, 
         },
        }
    );

    data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
    dispatch({ type: GET_ALL_FEEDS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_FEEDS_ERROR, payload: error });
  }
};


export const getFeedId = (id) => async (dispatch) => {
  try {
    await dispatch({ type: GET_FEED_ID_LOADING });
    //const config = await getAxiosConfig();
    const { data } = await axios.get(
      `https://xvufjkvibh.execute-api.us-east-1.amazonaws.com/api/dashboard/${id}`,
      {
        headers: {
        //   Authorization: `${session.accessToken.jwtToken}`,
           "x-api-key": "X97c1sUayGBCfivJgWYO6mvvWkmdtpl6IbVDOGlh",
           "Content-Type": "application/json",
           "Access-Control-Allow-Credentials": true, 
         },
        }
    );
    const response2 = await axios.get(`https://td7k6cgaz9.execute-api.us-east-1.amazonaws.com/customer/rating_review/${id}`);
    dispatch({ type: GET_FEED_ID, payload: data, rating: response2 });
  } catch (error) {
    dispatch({ type: GET_FEED_ID_ERROR, payload: error });
  }
};


export const getAllPersonas = () => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `https://xvufjkvibh.execute-api.us-east-1.amazonaws.com/api/personas/list-all`,
      {
        headers: {
           "x-api-key": "X97c1sUayGBCfivJgWYO6mvvWkmdtpl6IbVDOGlh",
           "Content-Type": "application/json",
           "Access-Control-Allow-Credentials": true, 
         },
        }
    );

    dispatch({ type: GET_ALL_PERSONAS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_PERSONAS_ERROR, payload: error });
  }
};

export const updatePersonas = (personaType, personaId, customerId, databillityId) => async (dispatch) => {
//  updatePersonas(personaType, selectedValue?.personaId, feedByID?.customerId, feedByID?.databillityId))
    try {
      let url = "";
      if(personaType=="primary"){
        url = "https://xvufjkvibh.execute-api.us-east-1.amazonaws.com/api/personas/primary/change"
      }
      else {
        url = 'https://xvufjkvibh.execute-api.us-east-1.amazonaws.com/api/personas/secondary/change';
      }
      const response = await axios.post(url, {
        "databillity_id": databillityId,
        "customer_id": customerId,
        "persona_id": personaId
      },
      {
        headers: {
        "x-api-key": "X97c1sUayGBCfivJgWYO6mvvWkmdtpl6IbVDOGlh",
        "Content-Type": "application/json" 
        }
      }
    );
    dispatch({ type: UPDATE_PERSONAS, payload: response });
  }
   catch (error) {
    dispatch({ type: UPDATE_PERSONAS_ERROR, payload: error });
  }
};