import React from "react";
import "./style.scss";
import { Empty, Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  return (
    <div className="emptyPage">
      <Empty description="Page Not Found">
        <Button type="primary" onClick={handleBack}>
          Back Home
        </Button>
      </Empty>
    </div>
  );
};

export default NotFound;
