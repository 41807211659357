import { Modal } from "antd";
import "./modal-styles.scss";
import { useSelector } from "react-redux";

const data = [
  {
    id: 1,
    label: "Discount driven",
    name: "discount-driven",
  },
  // {
  //   label: "Trend setter",
  //   name: "trend-setter",
  // },
  // {
  //   label: "Luxury",
  //   name: "luxury",
  // },
  // {
  //   label: "Exclusive",
  //   name: "exclusive",
  // },
  {
    id: 14,
    label: "Research oriented",
    name: "research-oriented",
  },
  {
    id: 9,
    label: "Brand conscious",
    name: "brand-conscious",
  },
  {
    id: 16,
    label: "Standard",
    name: "standard",
  },
  {
    id: 5,
    label: "Needs based",
    name: "needs-based",
  },
  {
    id: 2,
    label: "Seasonal",
    name: "seasonal",
  },
  {
    id: 3,
    label: "Organizational",
    name: "organizational",
  },
  {
    id: 4,
    label: "Impluse",
    name: "impluse",
  },
  {
    id: 7,
    label: "Social-Driven",
    name: "social-driven",
  },
  {
    id: 8,
    label: "Habitual",
    name: "habitual",
  },
  {
    id: 10,
    label: "Trendsetter",
    name: "trendsetter",
  },
  {
    id: 11,
    label: "Convenience",
    name: "convenience",
  },
  {
    id: 12,
    label: "Ethical",
    name: "ethical",
  },
  {
    id: 13,
    label: "Emotional",
    name: "emotional",
  },
  // {
  //   label: "Environmental",
  //   name: "environmental",
  // },
  {
    id: 15,
    label: "Loyal",
    name: "loyal",
  },
];

const EditPersonaModal = ({ open, onClose, onChange }) => {
  const { allPersona } = useSelector((state) => state.coPilot);

  return (
    <Modal
      title=""
      open={open}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      centered
      className="editPersonaPopup"
    >
      <h3>Edit Persona</h3>
      <div className="list">
        {allPersona &&
          allPersona.length > 0 &&
          allPersona.map((item, i) => {
            let name = item.TYPE_OF_CONSUMER;
            return (
              <p
                key={i}
                onClick={() => onChange({ name, id: item.ID })}
                className={`persona ${name
                  .toLowerCase()
                  .split(" ")
                  .map((word) => {
                    return word.replace(/-/g, "-");
                  })
                  .join("-")
                  .replace("/", "-")}`}
              >
                {name || ""}
              </p>
            );
          })}
      </div>
    </Modal>
  );
};

export default EditPersonaModal;
