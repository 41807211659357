import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
      primary: {
          main: '#002857'
      },
      secondary: {
          main: '#02AFEF'
      }
  },

  typography: {
    fontFamily: "Gotham, sans-serif", // Set Gotham as the default font
  },
});
