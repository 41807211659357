import { React, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Authenticate from "../../../components/Onboarding/Authenticate";
import CreateAccount from "../../../components/Onboarding/CreateAccount";

const CreateAccountPage = () => {
  const [activeStep, setActiveStep] = useState(0);

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });

  return activeStep === 0 ? (
    <CreateAccount setActiveStep={setActiveStep} />
  ) : activeStep === 1 ? (
    <Authenticate />
  ) : null;
};

export default CreateAccountPage;
