import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  jobFunctionList,
  accountRoleList,
  databilityUseOptionsList,
} from "../../../pages/Onboarding/constants.js";
import {
  cleanPhoneNumber,
  formatPhoneNumber,
} from "../../../utils/utilities.js";
import CancelModal from "../CancelModal/index.jsx";
import "./style.scss";
import { setBasicInfo } from "../../../redux/actions/onboarding.js";

const BasicInformation = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { onboarding, isLoading, error } = useSelector(
    (state) => state.onboarding
  );

  const handleCheckBoxChange = (e, option) => {
    const { checked } = e.target;
    const updatedDataUsage = checked
      ? [...formik.values.dataUsage, option.value]
      : formik.values.dataUsage.filter((value) => value !== option.value);
    formik.setFieldValue("dataUsage", updatedDataUsage);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    jobRole: "",
    jobFunction: "",
    accountRole: "",
    dataUsage: [],
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("Please enter your First Name")
      .matches(/\S/, "Invalid First name"),
    lastName: yup
      .string()
      .required("Please enter your Last name")
      .matches(/\S/, "Invalid Last name"),
    phone: yup
      .string()
      .required("Please enter phone number")
      .min(14, "Phone must be at least 10 characters")
      .matches(/\S/, "Invalid phone"),
    jobRole: yup
      .string()
      .required("Please enter your Job Role")
      .matches(/\S/, "Invalid Job Role"),
    jobFunction: yup.string().required("Please select an option"),
    accountRole: yup.string().required("Please select an option"),
    dataUsage: yup
      .array()
      .of(yup.string())
      .min(1, "At least one option must be selected")
      .required("At least one option must be selected"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        setBasicInfo({
          email: onboarding.account.email,
          first_name: values.firstName,
          last_name: values.lastName,
          phone: cleanPhoneNumber(values.phone),
          job_role: values.jobRole,
          job_function: values.jobFunction,
          databillity_choices: values.dataUsage.join(", "),
          account_level_role: values.accountRole,
        })
      );
    },
  });

  useEffect(() => {
    if (onboarding.basicInfo) {
      formik.setFieldValue("firstName", onboarding.basicInfo.first_name);
      formik.setFieldValue("lastName", onboarding.basicInfo.last_name);
      formik.setFieldValue(
        "phone",
        formatPhoneNumber(onboarding.basicInfo.phone)
      );
      formik.setFieldValue("jobRole", onboarding.basicInfo.job_role);
      formik.setFieldValue("jobFunction", onboarding.basicInfo.job_function);
      formik.setFieldValue(
        "accountRole",
        onboarding.basicInfo.account_level_role
      );
    }
    if (onboarding.basicInfo?.databillity_choices) {
      const dataUsageArray = onboarding.basicInfo.databillity_choices
        .split(",")
        .map((item) => item.trim());
      formik.setFieldValue("dataUsage", dataUsageArray);
    }
  }, [onboarding]);

  return (
    <Box className="infoForm">
      <Typography variant="h6" className="heading">
        Basic Information
      </Typography>
      <Typography variant="body1" className="subHeading">
        Please provide all the information below
      </Typography>
      <FormikProvider value={formik}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                First Name
              </Typography>
              <TextField
                placeholder="Jane"
                variant="outlined"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.firstName && formik.errors.firstName
                )}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Last Name
              </Typography>
              <TextField
                placeholder="Doe"
                variant="outlined"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.lastName && formik.errors.lastName
                )}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead mobileField">
                Phone
              </Typography>
              <TextField
                placeholder="999-999-9999"
                variant="outlined"
                name="phone"
                value={formik.values.phone}
                onChange={(e) =>
                  formik.setFieldValue(
                    "phone",
                    formatPhoneNumber(e.target.value)
                  )
                }
                error={Boolean(formik.touched.phone && formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                What is your job role?
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Placeholder"
                name="jobRole"
                value={formik.values.jobRole}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.jobRole && formik.errors.jobRole)}
                helperText={formik.touched.jobRole && formik.errors.jobRole}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                What is your job function?
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.jobFunction ? "" : "Select"}
                </InputLabel>
                <Select
                  name="jobFunction"
                  value={formik.values.jobFunction}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.jobFunction && formik.errors.jobFunction
                  )}
                >
                  {jobFunctionList.map((job, index) => (
                    <MenuItem
                      key={index}
                      value={job}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {job}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.errors.jobFunction && (
                <Typography variant="caption" color="error">
                  {formik.touched.jobFunction && formik.errors.jobFunction}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                How do you plan on using Datability? (Check all that apply)
              </Typography>
              <FormGroup>
                <Grid container spacing={2}>
                  {databilityUseOptionsList.map((option, index) => (
                    <Grid item xs={6} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="dataUsage"
                            checked={formik.values.dataUsage.includes(
                              option.value
                            )}
                            onChange={(event) =>
                              handleCheckBoxChange(event, option)
                            }
                          />
                        }
                        label={
                          <Typography variant="body2" className="formCheckbox">
                            {option.label}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              {formik.errors.dataUsage && (
                <Typography variant="caption" color="error">
                  {formik.touched.dataUsage && formik.errors.dataUsage}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                What is your account level role?
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.accountRole ? "" : "Select"}
                </InputLabel>
                <Select
                  name="accountRole"
                  value={formik.values.accountRole}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.accountRole && formik.errors.accountRole
                  )}
                >
                  {accountRoleList.map((role, index) => (
                    <MenuItem
                      key={index}
                      value={role}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.errors.accountRole && (
                <Typography variant="caption" color="error">
                  {formik.touched.accountRole && formik.errors.accountRole}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="formButtons">
              <Button variant="outlined" disabled>
                Back
              </Button>
              <Button variant="contained" onClick={formik.handleSubmit}>
                Next
              </Button>
              <CancelModal open={open} setOpen={setOpen} />
            </Box>
          </Grid>
        </Grid>
      </FormikProvider>
    </Box>
  );
};

export default BasicInformation;
