/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Space,
  Button,
  Popover,
  List,
  Avatar,
  Switch,
  Dropdown,
} from "antd";
import {
  BellFilled,
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
// import Switch from "react-switch";
import LogoMobile from "../../../assets/img/logo_mobile.svg";
import "./style.scss";
import { logout } from "../../../redux/actions/auth";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const [isNewNotification, setIsNewNotification] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(true);

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const databillityStr = localStorage.getItem("databillity");
  const username = localStorage.getItem("username");
  const databillity = databillityStr ? JSON.parse(databillityStr) : null;
  const switchDataStr = localStorage.getItem("switchData");
  const switchData = switchDataStr ? JSON.parse(switchDataStr) : null;

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });

  const [switchValues, setSwitchValues] = useState({
    signature: false,
    richmond: false,
  });

  useEffect(() => {
    if (switchData) {
      setSwitchValues(switchData);
    }
  }, [switchData]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (isUserDropdownOpen && !dropdownRef.current.contains(event.target))
        setIsUserDropdownOpen(false);
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isUserDropdownOpen]);
  const deleteCookie = (name, path, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain}`;
  };
  
  // Function to delete all cookies
  const deleteAllCookies = () => {
    // Get all cookies
    const cookies = document.cookie.split("; ");
  
    // Delete each cookie
    cookies.forEach(cookie => {
      const [name] = cookie.split("=");
      deleteCookie(name, "/", window.location.hostname);
    });
  };
  
  // Call deleteAllCookies to remove all cookies
 
  const handleLogout = () => {
    localStorage.removeItem("databillity_token");
    // const encodedRedirectUri = 'https://app.stg.databillity.com/onboarding';
    // const logoutUrlGoogle =  'https://www.google.com/accounts/Logout';
    // window.location.href = logoutUrlGoogle;

    localStorage.clear();
    sessionStorage.clear();
  
    deleteAllCookies();  
    dispatch(logout());
   // window.location = "https://mail.google.com/mail/u/0/?logout&hl=en";
    // navigate("/login");
  };

  const handleChangeSwitch = (checked, name) => {
    const obj = { ...switchValues, [name]: checked };
    localStorage.setItem("switchData", JSON.stringify(obj));
    setSwitchValues(obj);
  };

  const contentSetting = (
    <div>
      <List itemLayout="horizontal">
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                size={28}
                style={{
                  background: "#002857",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {username ? username.charAt() : ""}
              </Avatar>
            }
            title={`${(databillity && databillity.first_name) || ""} ${
              (databillity && databillity.last_name) || ""
            }`}
            description={(databillity && databillity.email) || ""}
          />
        </List.Item>
        <List.Item>
          <h5>Signature Mazda</h5>
          <div>
            <Switch
              size="small"
              checked={switchValues.signature}
              onChange={(e) => handleChangeSwitch(e, "signature")}
            />
          </div>
        </List.Item>
        <List.Item>
          <h5>Richmond Centre</h5>
          <div>
            <Switch
              size="small"
              checked={switchValues.richmond}
              onChange={(e) => handleChangeSwitch(e, "richmond")}
            />
          </div>
        </List.Item>
      </List>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <div className="header mobile">
          <div className="header-toggle" onClick={props.onClick}>
            <MenuOutlined />
          </div>
          <div className="header-logo">
            <img src={LogoMobile} alt="Logo" />
          </div>
          <div className="header-user">
            <Button
              shape="circle"
              type="link"
              icon={
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.8203 19L13.8203 13M1.82031 8C1.82031 8.91925 2.00137 9.82951 2.35316 10.6788C2.70494 11.5281 3.22055 12.2997 3.87056 12.9497C4.52058 13.5998 5.29225 14.1154 6.14153 14.4672C6.99081 14.8189 7.90106 15 8.82031 15C9.73957 15 10.6498 14.8189 11.4991 14.4672C12.3484 14.1154 13.12 13.5998 13.7701 12.9497C14.4201 12.2997 14.9357 11.5281 15.2875 10.6788C15.6393 9.82951 15.8203 8.91925 15.8203 8C15.8203 7.08075 15.6393 6.1705 15.2875 5.32122C14.9357 4.47194 14.4201 3.70026 13.7701 3.05025C13.12 2.40024 12.3484 1.88463 11.4991 1.53284C10.6498 1.18106 9.73957 1 8.82031 1C7.90106 1 6.99081 1.18106 6.14153 1.53284C5.29225 1.88463 4.52058 2.40024 3.87056 3.05025C3.22055 3.70026 2.70494 4.47194 2.35316 5.32122C2.00137 6.1705 1.82031 7.08075 1.82031 8Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />

            <Space>
              <Badge dot={isNewNotification} offset={[-3, 3]}>
                <p
                  className="header-user-info"
                  onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                >
                  {/* {databillity ? databillity.first_name?.charAt() : ""}
                  {databillity ? databillity.last_name?.charAt() : ""} */}
                  {username ? username.charAt() : ""}
                </p>
              </Badge>
            </Space>

            <div
              className="header-user-dropdown"
              style={{
                display: `${isUserDropdownOpen ? "flex" : "none"}`,
              }}
              ref={dropdownRef}
            >
              <p className="header-user-dropdown-item">
                <BellFilled /> Notification
              </p>
              <p
                className="header-user-dropdown-item"
                onClick={handleLogout}
              >
                <LogoutOutlined /> Logout
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={`header${isTablet ? " tablet" : ""}`}>
          <div className="header-toggle" onClick={props.onClick}>
            <MenuOutlined />
          </div>
          <div className="header-search">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Signature Mazda",
                  },
                  {
                    key: "2",
                    label: "Lougheed Highway",
                  },
                  {
                    key: "3",
                    label: "Byrne Road",
                  },
                  {
                    key: "4",
                    label: "Lougheed Highway",
                  },
                ],
              }}
            >
              <Button className="btnDropdown">
                Signature Mazda{" "}
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0L6.5 7L13 0H0Z"
                    fill="white"
                    fill-opacity="0.6"
                  />
                </svg>
              </Button>
            </Dropdown>
            {/* <form className="header-contorls-serachForm" autoComplete="off">
              <input type="hidden" name="serachKey" autoComplete="off" />
              <input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
              />
              <svg
                className="search-icon"
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8203 16L13.0235 12.2032M13.0235 12.2032C13.6329 11.5938 14.1163 10.8703 14.4461 10.0741C14.7759 9.2779 14.9457 8.42451 14.9457 7.56269C14.9457 6.70086 14.7759 5.84748 14.4461 5.05126C14.1163 4.25503 13.6329 3.53157 13.0235 2.92217C12.4141 2.31276 11.6907 1.82936 10.8944 1.49955C10.0982 1.16975 9.24483 1 8.383 1C7.52118 1 6.66779 1.16975 5.87157 1.49955C5.07535 1.82936 4.35188 2.31277 3.74248 2.92217C2.51174 4.15291 1.82031 5.82216 1.82031 7.56269C1.82031 9.30322 2.51174 10.9725 3.74248 12.2032C4.97322 13.434 6.64247 14.1254 8.383 14.1254C10.1235 14.1254 11.7928 13.434 13.0235 12.2032Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </form> */}
          </div>
          <div className="header-contorls">
            <ul className="header-contorls-list">
              <li className="">
                <Popover
                  content={contentSetting}
                  trigger={["click"]}
                  rootClassName="profileMenus"
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="header-contorls-list-loginUserName"
                  >
                    <Space>{username ? username.charAt() : ""}</Space>
                  </a>
                </Popover>
              </li>
              <li className="header-contorls-list-notification">
                <Badge dot={isNewNotification} offset={[-3, 3]}>
                  <BellFilled className="header-contorls-list-notification-icon" />
                </Badge>
              </li>
              <li className="header-contorls-list-logout">
                <p onClick={handleLogout}>
                  <LogoutOutlined className="header-contorls-list-logout-icon" />
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
