import React from "react";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { useMediaQuery } from "react-responsive";
import { Box, Button, Grid, Typography } from "@mui/material";
import BuyingJourneyImage from "../../../../assets/img/buying-journey.svg";
import BuyingJourneyMobileImage from "../../../../assets/img/buying-journey-mobile.svg";
import "../../../../pages/Reports/style.scss";
import "./style.scss";

const JourneyCard = ({ connections, services, campaignNumber }) => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1400 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const isNotXL = useMediaQuery({ maxWidth: 1535 });
  return (
    <Box
      className={
        isMobile ? `journeyCardDesign` : `desktopCard journeyCardDesign `
      }
    >
      <Box className="heading">
        <Typography variant="h6" sx={{ color: "#002857" }}>
          Buying Journey
        </Typography>
        {isMobile ? null : (
          <Button>
            <MoreHorizOutlinedIcon sx={{ color: "black" }} />
          </Button>
        )}
      </Box>

      <Box>
        <Grid container>
          <Grid item xl={2} xs={12}>
            <Box className="connectionSection">
              {connections.map((connection, index) => (
                <Box key={index} className="cardConnection">
                  {connection[3] ? connection[1] : connection[2]}
                  <Typography
                    variant="subtitle1"
                    className={
                      connection[3]
                        ? `blue cardHeading`
                        : `light-gray cardHeading`
                    }
                  >
                    {connection[0]}
                  </Typography>
                  <Typography
                    variant="caption"
                    className={connection[3] ? "gray" : "light-gray"}
                  >
                    Campaign #{campaignNumber}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item xl={8} xs={12}>
            <Box className="buyingJourneyImgBox">
              <img
                src={isNotXL ? BuyingJourneyMobileImage : BuyingJourneyImage}
                alt="Buying Journey"
                className={
                  isNotXL ? `buyingJourneyMobileImage` : `buyingJourneyImage`
                }
              />
            </Box>
          </Grid>

          <Grid item xl={2} xs={12}>
            <Box className="serviceSection">
              {services.map((service, index) => (
                <Box key={index} className="cardService">
                  <Box className="serviceCircle">
                  {service[3] ? service[1] : service[2]}
                    <Typography
                      variant="subtitle1"
                      className={
                        service[3]
                          ? `blue`
                          : `light-gray`
                      }
                      sx={{ fontSize: "15px" }}
                    >
                      {service[0]}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default JourneyCard;
