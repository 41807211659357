import {
  Box,
  Button,
  Chip,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import "../../../pages/Reports/style.scss";
import "./style.scss";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Col, Row } from 'antd';


const Recommendations = (props) => {
  const { name, image, description, price } = props;
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const skeletonCard = () => {
    return (
      <Box className={!isMobile? "segmentBio": ""} sx={{ p: 3 }} style={{backgorundColor:"#f8f8f8"}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "28px",
          }}
        >
          <Box>
          <Grid item xl={12} lg={12} sm={5} xs={12}>
            <Typography variant="h6" className="blue">
              <Skeleton className="skeletonHeading" active />
            </Typography>
            <Typography
              variant="body2"
              className="dark-gray"
              sx={{ paddingBottom: 2 }}
            >
              <Skeleton className="skeletonText" active />
            </Typography>
          </Grid>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={1} alignItems="stretch">
            
            <Grid item xl={12} lg={12} sm={5} xs={12}>
              <Skeleton className="skeletonBadge" style={{width:"auto", height:"50px"}} active />
              <Skeleton className="skeletonBadge" style={{width:"auto", height:"50px"}} active />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };


  return (
    <>
      {loading ? (
        skeletonCard()
      ) : (
              <Grid item md={12} xs={12} mb={12}>
{/* //                 <Row gutter={16}>
//  <Col span={12} style={{display: 'flex'}}> */}
                <Card style={{  borderRadius: "12px",height:"100%", minWidth:"fit-content"}}>
                  <CardMedia
                    component="img"
                    alt="Auto Detailing"
                    height="140"
                    image={image}
                  />
                  <CardContent height="240" style={{ textAlign: "center" }}>
                    <Typography variant="body1" className="blue bold-700" style={{whiteSpace:"nowrap"}}>
                      <span>{name} </span>
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                      {description}
                    </Typography>
                  </CardContent>
                  <CardActions height="70" style={{ marginLeft: isMobile || isTablet? "0%":"5%" }}>
                    <Typography variant="body1" className="blue bold-700">
                      <span>${price} </span>
                    </Typography>
                    <Button
                      variant="contained"
                      style={{
                        marginLeft: "10%",
                        minWidth: "fit-content",
                        fontSize: "80%",
                        justifyContent: "flex-end",
                        marginLeft: "auto"
                      }}
                    >
                      Recommend
                    </Button>
                  </CardActions>
                </Card>
                {/* </Col>
  </Row> */}
</Grid>
 
       
      )}
    </>
  );
};

export default Recommendations;
