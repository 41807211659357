import React, { useState, useEffect } from "react";
import { Drawer, Skeleton, Carousel, Switch, Empty, Button } from "antd";
import { Icon } from "@iconify/react";
import ArrowBackIcon from "@iconify-icons/ic/outline-arrow-forward";
import MailIcon from "@iconify-icons/ic/mail-outline";
import PhoneIcon from "@iconify-icons/bx/bxs-phone";
import MasterCardIcon from "../../../assets/img/icons/icons8-mastercard-logo-48.svg";
// import VisaIcon from "../../../assets/img/visa.png";
import "./style.scss";
import AirImg from "../../../assets/img/air-miles.svg";
import PIcon from "../../../assets/img/icons/p-icon.svg";
import SIcon from "../../../assets/img/icons/s-icon.png";
import NoImage from "../../../assets/img/no-image.svg";
import moment from "moment";
import RateAndReviews from "./RateAndReviews";
import { useDispatch, useSelector } from "react-redux";
import AvatarStatus from "../AvatarStatus";
import {
  getCustomerData,
  getUserRateWithId,
  resetStateCoPilot,
  updatePersona,
  updateSecondaryPersona,
} from "../../../redux/actions/co-pliot";
import { EditFilled } from "@ant-design/icons";
import EditPersonaModal from "./EditPersonaModal";
import { toast } from "react-toastify";

const desktopFormat = "MM/DD/YY H:mma";
const mobileFormat = "MM/DD/YY H:mma";
const timeFormat = "H:mm a";

const targetIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12S5.373 0 12 0m0 19.826a7.827 7.827 0 1 0 0-15.652a7.827 7.827 0 0 0 0 15.652m0-3.985a3.84 3.84 0 1 1 0-7.68a3.84 3.84 0 0 1 0 7.68"
    />
  </svg>
);

const CustomerDetailDrawer = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, customer, isMobile, isLoading, onLoadData } = props;
  const { userRating, updatedPersona } = useSelector((state) => state.coPilot);

  const [checked, setChecked] = useState(true);
  const [view, setView] = useState("detail"); // detail, rating, reviews
  const [indexPersona, setIndexPersona] = useState(null);
  const [openPersona, setOpenPersona] = useState(false);
  const [personaData, setPersonaData] = useState(["Needs Based"]);

  const handleClosePersona = () => {
    setOpenPersona(false);
    setIndexPersona(null);
  };

  const handleEditPersona = (index) => {
    setOpenPersona(true);
    setIndexPersona(index);
  };

  const handleChangePersona = ({ name, id }) => {
    // let newArr = [];
    // for (let i = 0; i < personaData.length; i++) {
    //   const el = personaData[i];
    //   if (i === indexPersona) {
    //     newArr.push(name);
    //   } else {
    //     newArr.push(el);
    //   }
    // }
    // setPersonaData(newArr);
    if (indexPersona === 1) {
      dispatch(
        updatePersona({
          customer_id: customer.id,
          persona_id: id,
        })
      );
    } else {
      dispatch(
        updateSecondaryPersona({
          customer_id: customer.id,
          persona_id: id,
        })
      );
    }
  };

  const handleBack = () => {
    if (view === "detail") {
      onClose();
      setPersonaData(["Needs Based"]);
    } else if (view === "rating") {
      setView("detail");
    } else if (view === "reviews") {
      setView("rating");
    }
  };

  const getTimeFormat = (time) => {
    let date = moment.utc(time).local();
    let checkValid = date.isValid();
    if (!checkValid) {
      return "--";
    }
    let showTime = "";
    const _currentYear = moment().year();
    const _year = moment(time).year();
    const _currentMonth = moment().month();
    const _month = moment(time).month();
    const _currentWeek = moment().week();
    const _week = moment(time).week();
    const _currentDay = moment().date();
    const _day = moment(time).date();

    if (_currentYear > _year) {
      showTime = moment.utc(time).local().format("MMM DD, YYYY");
    } else if (_currentMonth > _month) {
      showTime = moment.utc(time).local().format("MMM D");
    } else if (_currentDay === _day) {
      // showTime = moment.utc(time).local().format("H:mm");
      // showTime = moment.utc(time).local().format("H:mm");
      showTime = "Today";
    } else if (_currentWeek === _week) {
      showTime = moment.utc(time).local().format("dddd");
    } else if (_currentWeek > _week) {
      showTime = moment.utc(time).local().format("MMM D");
    } else if (_currentDay - 1 === _day) {
      showTime = "Yesterday";
    } else {
      showTime = moment.utc(time).local().format("MMM D");
    }

    return showTime;
  };

  useEffect(() => {
    if (customer && customer.id) {
      dispatch(getUserRateWithId(customer.id));
      // if (customer.persona) {
      //   let arr = personaData;
      //   arr.unshift(customer.persona);
      //   setPersonaData(arr);
      // }
    }

    // return () => {
    //   setPersonaData(["Needs Based"]);
    // };
  }, [customer]);

  useEffect(() => {
    if (updatedPersona === true) {
      handleClosePersona();
      dispatch(resetStateCoPilot());
      dispatch(getCustomerData(customer.id));
      onLoadData();
      toast.success("Persona updated successfully!");
    }
  }, [updatedPersona]);

  const renderSliders = () => {
    let slides = [];

    let results = [];
    let sliePerPage = 3;

    if (customer && customer.preferences) {
      for (
        let i = 0;
        i < Math.ceil(customer.preferences.length / sliePerPage);
        i++
      ) {
        results.push(
          <div key={i}>
            {customer.preferences
              .slice(i * sliePerPage, (i + 1) * sliePerPage)
              .map((item, idx) => {
                if (item.preference) {
                  return (
                    <div
                      className={`drawer-content-detail-preferences-preference card-el ${customer?.persona
                        ?.toLowerCase()
                        .split(" ")
                        .map((word) => {
                          return word.replace(/-/g, " ");
                        })
                        .join("-")}`}
                      key={idx}
                    >
                      {/* <img
                        className="drawer-content-detail-preferences-preference-icon"
                        src={item.icon}
                        alt="grid icon"
                      /> */}
                      <span className="drawer-content-detail-preferences-preference-icon">
                        {targetIcon}
                      </span>
                      {item.preference}
                    </div>
                  );
                } else {
                  return "";
                }
              })}
          </div>
        );
      }
    }
    if (customer && customer.secondary_preferences) {
      for (
        let i = 0;
        i < Math.ceil(customer.secondary_preferences.length / sliePerPage);
        i++
      ) {
        results.push(
          <div key={i}>
            {customer.secondary_preferences
              .slice(i * sliePerPage, (i + 1) * sliePerPage)
              .map((item, idx) => {
                if (item.preference) {
                  return (
                    <div
                      className={`drawer-content-detail-preferences-preference card-el ${customer?.secondary_persona
                        ?.toLowerCase()
                        .split(" ")
                        .map((word) => {
                          return word.replace(/-/g, " ");
                        })
                        .join("-")}`}
                      key={idx}
                    >
                      {/* <img
                        className="drawer-content-detail-preferences-preference-icon"
                        src={item.icon}
                        alt="grid icon"
                      /> */}
                      <span className="drawer-content-detail-preferences-preference-icon">
                        {targetIcon}
                      </span>
                      {item.preference}
                    </div>
                  );
                } else {
                  return "";
                }
              })}
          </div>
        );
      }
    }

    return results;
  };

  const skeletonRecommen = () => {
    return (
      <div className="drawer-content-detail-recommendations-cards">
        <div className="drawer-content-detail-recommendations-cards-card">
          <Skeleton.Avatar
            shape="square"
            active
            style={{ width: "54px", height: "54px" }}
          />
          <div className="drawer-content-detail-recommendations-cards-card-inform">
            <Skeleton.Button
              active
              loading={isLoading}
              size="small"
              block
              style={{ margin: "10px 0 15px", height: "16px" }}
            />
            <Skeleton.Button
              active
              loading={isLoading}
              size="small"
              block
              style={{ height: "16px" }}
            />
          </div>
        </div>
        <div className="drawer-content-detail-recommendations-cards-card">
          <Skeleton.Avatar
            shape="square"
            active
            style={{ width: "54px", height: "54px" }}
          />
          <div className="drawer-content-detail-recommendations-cards-card-inform">
            <Skeleton.Button
              active
              loading={isLoading}
              size="small"
              block
              style={{ margin: "10px 0 15px", height: "16px" }}
            />
            <Skeleton.Button
              active
              loading={isLoading}
              size="small"
              block
              style={{ height: "16px" }}
            />
          </div>
        </div>
        <div className="drawer-content-detail-recommendations-cards-card">
          <Skeleton.Avatar
            shape="square"
            active
            style={{ width: "54px", height: "54px" }}
          />
          <div className="drawer-content-detail-recommendations-cards-card-inform">
            <Skeleton.Button
              active
              loading={isLoading}
              size="small"
              block
              style={{ margin: "10px 0 15px", height: "16px" }}
            />
            <Skeleton.Button
              active
              loading={isLoading}
              size="small"
              block
              style={{ height: "16px" }}
            />
          </div>
        </div>
        <div className="drawer-content-detail-recommendations-cards-card">
          <Skeleton.Avatar
            shape="square"
            active
            style={{ width: "54px", height: "54px" }}
          />
          <div className="drawer-content-detail-recommendations-cards-card-inform">
            <Skeleton.Button
              active
              loading={isLoading}
              size="small"
              block
              style={{ margin: "10px 0 15px", height: "16px" }}
            />
            <Skeleton.Button
              active
              loading={isLoading}
              size="small"
              block
              style={{ height: "16px" }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      placement="right"
      size="large"
      onClose={() => {
        onClose();
        setView("detail");
        setPersonaData(["Needs Based"]);
      }}
      open={isOpen}
      headerStyle={{ display: "none" }}
      bodyStyle={{ padding: 0 }}
      width={`${isMobile ? "100%" : 768}`}
    >
      <div className={`drawer ${isMobile ? "mobile" : ""}`}>
        <div className="drawer-header">
          <p className="drawer-header-close" onClick={handleBack}>
            <Icon
              icon={ArrowBackIcon}
              style={{ transform: view === "reviews" ? "rotate(180deg)" : "" }}
            />
          </p>
          {view !== "detail" ? (
            <div style={{ height: "44px" }}></div>
          ) : (
            <div className="drawer-header-state">
              {isLoading ? (
                <Skeleton.Button
                  size="small"
                  shape="round"
                  active
                  loading={isLoading}
                  style={{ minWidth: "220px", height: "16px" }}
                />
              ) : (
                <p className="drawer-header-state-status">
                  {getTimeFormat(customer && customer.checkin_time)}
                </p>
              )}

              {isLoading ? (
                <Skeleton.Button
                  size="small"
                  shape="round"
                  active
                  loading={isLoading}
                  style={{ minWidth: "220px", height: "16px" }}
                />
              ) : (
                <p className="drawer-header-state-checkDate">
                  {/* {!isMobile && "Check-in-time |"}{" "} */}
                  <span className="drawer-header-state-checkDate-date">
                    {customer && customer.checkin_time
                      ? moment
                          .utc(customer.checkin_time)
                          .local()
                          .format(timeFormat)
                      : ""}
                  </span>
                </p>
              )}
            </div>
          )}
        </div>
        <div className="drawer-content">
          <div className="avatar-desktop">
            <AvatarStatus status={customer.status} />
          </div>
          <div className="drawer-content-detail">
            <div className="drawer-content-detail-commonInfo">
              <div className="avatar-mobile">
                <AvatarStatus status={customer.status} />
              </div>
              <div className="drawer-content-detail-commonInfo-box">
                <div className="drawer-content-detail-commonInfo-name">
                  {isLoading ? (
                    <Skeleton.Button
                      active
                      shape="round"
                      size="small"
                      style={{ minWidth: "142px" }}
                    />
                  ) : (
                    customer?.name
                  )}

                  {!isLoading && (
                    <Button
                      className="btnRate"
                      onClick={() => setView("rating")}
                    >
                      <svg
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.0518 15L4.36441 9.45253L0 5.72289L5.74899 5.23215L8.00053 0L10.2521 5.2311L16 5.72184L11.6367 9.45148L12.9493 14.999L8.00053 12.0545L3.0518 15Z"
                          fill="#999999"
                        />
                      </svg>
                      {(userRating &&
                        userRating.customer_rating_average &&
                        Math.round(userRating.customer_rating_average * 10) /
                          10) ||
                        0}
                    </Button>
                  )}
                </div>

                <p className="drawer-content-detail-commonInfo-email">
                  <Icon icon={MailIcon} />
                  {isLoading ? (
                    <Skeleton.Button
                      active
                      shape="round"
                      size="small"
                      style={{ minWidth: "120px", height: "16px" }}
                    />
                  ) : (
                    customer?.email
                  )}
                </p>
                <p className="drawer-content-detail-commonInfo-phonenumber">
                  <Icon icon={PhoneIcon} />{" "}
                  {isLoading ? (
                    <Skeleton.Button
                      active
                      shape="round"
                      size="small"
                      style={{ minWidth: "120px", height: "16px" }}
                    />
                  ) : (
                    (customer && customer.phone !== "null" && customer.phone) ||
                    "No Phone"
                  )}
                </p>
              </div>
            </div>
            {view !== "detail" ? (
              <RateAndReviews
                onClose={onClose}
                userRating={userRating}
                customer_id={customer.id}
                onView={setView}
                view={view}
              />
            ) : (
              <>
                <div className="drawer-content-detail-second">
                  <div className="drawer-content-detail-second-persona">
                    Persona
                    <div className="drawer-content-detail-second-status">
                      {/* {personaData &&
                        personaData.length > 0 &&
                        personaData.map((item, i) => (
                          <div
                            key={i}
                            className="drawer-content-detail-second-status-item"
                          >
                            <img
                              className="drawer-content-detail-second-status-icon"
                              alt="icon"
                              src={item === "Needs Based" ? SIcon : PIcon}
                            />

                            {isLoading ? (
                              <Skeleton.Button
                                active
                                loading={isLoading}
                                size="small"
                                style={{ minWidth: "120px" }}
                              />
                            ) : (
                              <p
                                className={`drawer-content-detail-second-persona-label persona ${item
                                  ?.toLowerCase()
                                  .split(" ")
                                  .map((word) => {
                                    return word.replace(/-/g, " ");
                                  })
                                  .join("-")}`}
                              >
                                {item}
                              </p>
                            )}
                            {!isLoading && (
                              <Button
                                type="text"
                                size="small"
                                onClick={() => handleEditPersona(i)}
                              >
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.8067 2.695C12.0667 2.435 12.0667 2.00167 11.8067 1.755L10.2467 0.195C10 -0.065 9.56667 -0.065 9.30667 0.195L8.08 1.415L10.58 3.915M0 9.50167V12.0017H2.5L9.87333 4.62167L7.37333 2.12167L0 9.50167Z"
                                    fill="#707070"
                                  />
                                </svg>
                              </Button>
                            )}
                          </div>
                        ))} */}
                      <div className="drawer-content-detail-second-status-item">
                        {isLoading ? (
                          <Skeleton.Button
                            active
                            loading={isLoading}
                            size="small"
                            style={{ minWidth: "120px" }}
                          />
                        ) : (
                          <p
                            className={`drawer-content-detail-second-persona-label persona ${customer?.persona
                              ?.toLowerCase()
                              .split(" ")
                              .map((word) => {
                                return word.replace(/-/g, " ");
                              })
                              .join("-")}`}
                          >
                            {customer?.persona}
                          </p>
                        )}
                        <Button
                          type="text"
                          size="small"
                          onClick={() => handleEditPersona(1)}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.8067 2.695C12.0667 2.435 12.0667 2.00167 11.8067 1.755L10.2467 0.195C10 -0.065 9.56667 -0.065 9.30667 0.195L8.08 1.415L10.58 3.915M0 9.50167V12.0017H2.5L9.87333 4.62167L7.37333 2.12167L0 9.50167Z"
                              fill="#707070"
                            />
                          </svg>
                        </Button>
                      </div>
                      <div className="drawer-content-detail-second-status-item">
                        {isLoading ? (
                          <Skeleton.Button
                            active
                            loading={isLoading}
                            size="small"
                            style={{ minWidth: "80px" }}
                          />
                        ) : (
                          <p
                            className={`drawer-content-detail-second-persona-label persona ${customer?.secondary_persona
                              ?.toLowerCase()
                              .split(" ")
                              .map((word) => {
                                return word.replace(/-/g, " ");
                              })
                              .join("-")}`}
                          >
                            {customer?.secondary_persona}
                          </p>
                        )}
                        <Button
                          type="text"
                          size="small"
                          onClick={() => handleEditPersona(2)}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.8067 2.695C12.0667 2.435 12.0667 2.00167 11.8067 1.755L10.2467 0.195C10 -0.065 9.56667 -0.065 9.30667 0.195L8.08 1.415L10.58 3.915M0 9.50167V12.0017H2.5L9.87333 4.62167L7.37333 2.12167L0 9.50167Z"
                              fill="#707070"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="drawer-content-detail-second-ltv">
                    LTV
                    {isLoading ? (
                      <Skeleton.Button
                        active
                        loading={isLoading}
                        size="small"
                        style={{ minWidth: "60px" }}
                      />
                    ) : (
                      <p className="drawer-content-detail-second-ltv-label">
                        ${customer && customer.ltv ? `${customer.ltv}` : "--"}
                      </p>
                    )}
                  </div>
                  <div className="drawer-content-detail-second-power">
                    Power
                    {isLoading ? (
                      <Skeleton.Button
                        active
                        loading={isLoading}
                        size="small"
                        style={{ minWidth: "60px" }}
                      />
                    ) : (
                      <p className="drawer-content-detail-second-power-label">
                        {(customer && customer.power) || "0%"}
                      </p>
                    )}
                  </div>
                </div>
                <div className="drawer-content-detail-preferences">
                  <h1 className="drawer-content-detail-preferences-title">
                    Preferences
                  </h1>

                  {isLoading ? (
                    <div>
                      <Skeleton.Button
                        active
                        loading={isLoading}
                        size="small"
                        block
                        style={{ marginBottom: "10px" }}
                      />
                      <Skeleton.Button
                        active
                        loading={isLoading}
                        size="small"
                        block
                        style={{ marginBottom: "10px" }}
                      />
                      <Skeleton.Button
                        active
                        loading={isLoading}
                        size="small"
                        block
                        // style={{ minWidth: "60px" }}
                      />
                    </div>
                  ) : customer?.preferences ? (
                    <div className="drawer-content-detail-sliders">
                      <Carousel>{renderSliders()}</Carousel>
                    </div>
                  ) : (
                    <Empty />
                  )}
                </div>

                <div className="drawer-content-detail-recommendations">
                  <div className="drawer-content-detail-recommendations-header">
                    <h1 className="drawer-content-detail-recommendations-title">
                      Recommendations
                    </h1>

                    <div className="drawer-content-detail-recommendations-switch">
                      <p>Product</p>
                      <Switch
                        checked={checked}
                        onChange={(value) => setChecked(value)}
                      />
                      <p>Reward</p>
                    </div>
                  </div>
                  {isLoading ? (
                    skeletonRecommen()
                  ) : (
                    <div className="drawer-content-detail-recommendations-cards">
                      {customer &&
                        customer.recomendations &&
                        customer.recomendations.length > 0 &&
                        customer.recomendations.map((item, idx) => (
                          <div
                            className="drawer-content-detail-recommendations-cards-card"
                            key={idx}
                          >
                            <img
                              className={`drawer-content-detail-recommendations-cards-card-image ${
                                item.image === ""
                                  ? "drawer-content-detail-recommendations-cards-card-no-image"
                                  : ""
                              }`}
                              src={
                                idx === 3
                                  ? AirImg
                                  : item.image !== ""
                                  ? item.image
                                  : NoImage
                              }
                              alt="card-img"
                            />
                            <div className="drawer-content-detail-recommendations-cards-card-inform">
                              <p className="drawer-content-detail-recommendations-cards-card-inform-title">
                                {idx === 3 ? "Airmiles" : item.recommendation}
                              </p>
                              <p className="drawer-content-detail-recommendations-cards-card-inform-price">
                                {item.price}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {!(customer && customer.recomendations) && !isLoading && (
                    <div style={{ textAlign: "center" }}>
                      <Empty />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* </Spin> */}

      <EditPersonaModal
        open={openPersona}
        onChange={handleChangePersona}
        onClose={handleClosePersona}
      />
    </Drawer>
  );
};

export default CustomerDetailDrawer;
