import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Switch,
  Chip,
  Skeleton,
  Rating,
  Stack,
} from "@mui/material";
import "../../../../pages/Reports/style.scss";
import "./style.scss";

const MetricsCard = (props) => {
  const {
    title,
    amount,
    color,
    percent,
    icon,
    arrow,
    isPercentage = true,
    isToggle = false,
    isTime = false,
    isLoading,
    isRatingsCard = false,
    rating,
    totalRating,
    users,
  } = props;

  const [loading, setLoading] = useState(true);
  const [newRating, setNewRating] = useState();
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(isLoading);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  const formatAmount = (value) => {
    const hasDecimalParts = value % 1 !== 0;
    const minimumFractionDigits = hasDecimalParts ? 2 : 0;
    const maximumFractionDigits = hasDecimalParts ? 2 : 0;
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  };

  const formatNumber = (value) => {
    const hasDecimalParts = value % 1 !== 0;
    return hasDecimalParts ? value?.toFixed(2) : value;
  };

  const convertDecimalHoursToTime = (decimalHours) => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    let formattedHours = date.getHours();
    const formattedMinutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = formattedHours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    formattedHours = formattedHours % 12;
    formattedHours = formattedHours ? formattedHours : 12; // The hour '0' should be '12'
    formattedHours = String(formattedHours).padStart(2, "0");

    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return formattedTime;
  };

  const skeletonCard = () => {
    return (
      <Box className="metricsCard segmentStatsCard">
        <Box
          className="cardUpperSection"
          sx={{ marginBottom: "0px !important" }}
        >
          <Skeleton
            variant="circular"
            className="iconBox skeletonAvatar"
            active
          />
          <Box>
            <Typography
              variant="body1"
              className="dark-gray bold-500"
              style={{ marginTop: "10px" }}
            >
              <Skeleton className="skeletonHeading" active />
            </Typography>
          </Box>
          {isPercentage ? (
            <Skeleton className="skeletonChip percentageChip" active />
          ) : null}
        </Box>
        <Typography className="metricsCardAmount">
          <Skeleton className="skeletonValue" active />
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {loading ? (
        skeletonCard()
      ) : (
        <Box className="metricsCard segmentStatsCard">
          <Box className="cardUpperSection">
            <Box className="iconBox">{icon}</Box>
            <Box>
              <Typography
                variant="body1"
                className="dark-gray bold-500"
                style={{ marginTop: "10px", marginRight: "10px" }}
              >
                {title}
              </Typography>
            </Box>
            {isPercentage ? (
              <Chip
                icon={arrow}
                label={`${formatAmount(percent)}%`}
                variant="filled"
                size="small"
                className={`percentageChip ${color}`}
              />
            ) : null}
            {isToggle ? (
              <Switch
                checked={toggle}
                onChange={() => setToggle(!toggle)}
                sx={{
                  marginTop: "5px",
                  marginLeft: "auto",
                  padding: "7px",
                  color: "primary",
                  "& .MuiSwitch-switchBase": {
                    transitionDuration: "800ms",
                  },
                  "& .MuiSwitch-track": {
                    borderRadius: 22 / 2,
                    backgroundColor: "#002857",
                    opacity: 1,
                  },
                }}
              />
            ) : null}
          </Box>
          {isRatingsCard ? (
            <Box>
              <Box className="ratingBoxSegment">
                <Box>
                  <Typography className="rating">
                    {newRating || formatNumber(rating)}
                  </Typography>
                  <Typography component="legend">
                    out of {totalRating}
                  </Typography>
                </Box>
                <Box>
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating"
                      defaultValue={formatNumber(rating)}
                      onChange={(event, newValue) => setNewRating(newValue)}
                      precision={0.5}
                    />
                  </Stack>
                </Box>
              </Box>
              <Typography className="ratingDescription">
                {newRating || formatNumber(rating)}-star average from more than{" "}
                {users} users
              </Typography>
            </Box>
          ) : (
            <Typography className="metricsCardAmount">
              {isTime
                ? toggle
                  ? `${convertDecimalHoursToTime(amount?.avgTime)}`
                  : `${convertDecimalHoursToTime(amount?.medianTime)}`
                : `$${formatAmount(amount)}`}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default MetricsCard;
