import {
  Box,
  Grid,
  Typography,
  Avatar,
  Skeleton,
  Item,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Images from "../../../assets/img/ImgConstants";
import "../../../pages/Reports/style.scss";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllFeeds, getFeedId, getAllRecommendations } from "../../../redux/actions/dashboard";
import woman from "../../../assets/img/woman.png";
import Badge from "@mui/material/Badge";
import RefreshIcon from "@mui/icons-material/Refresh";

const BioCard = () => {
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { feedsData, feedsDate } = useSelector((state) => state.dashboard);
  const [allFeeds, setAllFeeds] = useState();
  const [refreshDate, setRefreshDate] = useState();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(loading);
    }, 3000);

    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
   setLoading(true);
    dispatch(getAllFeeds());
  }, []);
  const compareDates = (a, b) => {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (feedsData) {
      let data = feedsData.sort(compareDates);
      setAllFeeds(data);
      setLoading(false);
      
    }
    let currentTime = changeTimeFormat();
    setRefreshDate(feedsDate + " " + currentTime);
  }, [feedsData]);

  const skeletonCard = () => {
    return (
      <Box className="segmentBio" style={{ height: "inherit" }}>
        <Grid
          container
          className="bioUpperSection"
          style={{ marginBottom: "7px", backgroundColor:"white" }}
        >
          <Grid xs={12}>
            <Skeleton animation="wave" />
          </Grid>
          <Grid
            sx={{ maxHeight: "100%", marginTop: "5%" }}
            container
            spacing={2}
          >
            <Grid
              container
              direction="row"
              style={{
                justifyContent: "center",
                display: "flex",
                padding: "2%",
              }}
            >
              <Grid
                item
                xs={12}
                className="feedCentre"
                //style={{ marginLeft: "20%" }}
              >
                <Skeleton variant="circular">
                  <Avatar sx={{ display: "flex", width: 70, height: 70 }} />
                </Skeleton>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
            <Skeleton
              animation="wave"
              sx={{ width: "40px", width: "100px" }}
            />
          </Grid>
          <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
            <Skeleton
              animation="wave"
              sx={{ width: "40px", width: "100px"}}
            />
          </Grid>
          <Grid
            sx={{ maxHeight: "100%", marginTop: "5%" }}
            container
            spacing={2}
          >
            <Grid
              container
              direction="row"
              style={{
                justifyContent: "center",
                display: "flex",
                padding: "2%",
              }}
            >
              <Grid
                item
                xs={12}
                className="feedCentre"
              >
                <Skeleton variant="circular">
                  <Avatar sx={{ display: "flex", width: 70, height: 70 }} />
                </Skeleton>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
            <Skeleton
              animation="wave"
              sx={{ width: "40px", width: "100px"}}
            />
          </Grid>
          <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
            <Skeleton
              animation="wave"
              sx={{ width: "40px", width: "100px"}}
            />
          </Grid>
          <Grid
            sx={{ maxHeight: "100%", marginTop: "5%" }}
            container
            spacing={2}
          >
            <Grid
              container
              direction="row"
              style={{
                justifyContent: "center",
                display: "flex",
                padding: "2%",
              }}
            >
              <Grid
                item
                xs={12}
                className="feedCentre"
              >
                <Skeleton variant="circular">
                  <Avatar sx={{ display: "flex", width: 70, height: 70 }} />
                </Skeleton>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
            <Skeleton
              animation="wave"
              sx={{ width: "40px", width: "100px"}}
            />
          </Grid>
          <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
            <Skeleton
              animation="wave"
              sx={{ width: "40px", width: "100px"}}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };
  const userData = [
    {
      name: "Mark Jones",
      rating: 4.56,
      color: "lightgreen",
    },
    {
      name: "Neera Patel",
      rating: 4.65,
      color: "lightgreen",
    },
    {
      name: "Naresh Patel",
      rating: 4.65,
      color: "lightgreen",
    },
    {
      name: "Naresh Patel",
      rating: 4.65,
      color: "lightgreen",
    },
    {
      name: "Naresh Patel",
      rating: 4.65,
      color: "lightgreen",
    },
  ];

  function userDetailData(id) {
    if(id){
    dispatch(getFeedId(id));
    dispatch(getAllRecommendations(id));
    }
    else{
      setLoading(true);
        
    }
  }
  function fetchDetails() {
    setLoading(true);
    dispatch(getAllFeeds());
  }

  function changeTimeFormat() {
    let date = new Date();

    let formatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    let formattedTime = formatter.format(date);
    return formattedTime;
  }

  const statusIndicator = (checkinTime) => {
    var givenDate = new Date(checkinTime);
    var currentDate = new Date();
    var timeDifference = currentDate - givenDate;
    var hoursDifference = timeDifference / (1000 * 60 * 60);
    if (hoursDifference <= 1) {
      return "green";
    } else if (hoursDifference > 1 && hoursDifference <= 2) {
      return "Yellow";
    } else {
      return "grey";
    }

  };

  return (
    <>
      {loading ? (
        skeletonCard()
      ) : (
        <Box className="segmentBio" style={{ height: "inherit" }}>
          <Grid
            container
            className="bioUpperSection"
            style={{ marginBottom: "7px", backgroundColor:"white" }}
          >
            <Grid xs={12}>
              <Typography variant="body1" className="blue bold-700">
                <span>
                  Feed{" "}
                  <RefreshIcon
                    style={{ float: "right", cursor: "pointer" }}
                    onClick={() => fetchDetails()}
                  />
                </span>
              </Typography>
              <Typography variant="body2">
                <span
                  style={{
                    color: "#999999",
                    fontSize: "80%",
                    fontWeight: "500",
                  }}
                >
                  {refreshDate} {/* {changeTimeFormat()}{" "} */}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              overflowY: "scroll",
              maxHeight: allFeeds?.length <= 3 ? "100%" : "80%",
            }}
            container
            spacing={2}
          >
            {" "}
            {allFeeds?.map((card, index) => (
              <Grid
                container
                direction="row"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  padding: "2%"                }}
                onClick={() => userDetailData(card.id)}
              >
                <Grid
                  item
                  xs={12}
                  className="feedCentre"
                  style={{ marginLeft: "20%" }}
                >
                  <Badge
                    overlap="circular"
                    badgeContent=" "
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiBadge-badge": {
                        color: `${statusIndicator(card.time)}`,
                        backgroundColor: `${statusIndicator(card.time)}`,
                      },
                    }}
                  >
                    <Avatar
                      alt="Bio Image"
                      src={woman}
                      sx={{ display: "flex", width: 70, height: 70 ,cursor: "pointer"
                    }}
                    />
                  </Badge>
                  <span
                    style={{
                      color: "#999999",
                      fontSize: "70%",
                      fontWeight: "500",
                      marginLeft: "5%",
                    }}
                  >
                    {new Intl.DateTimeFormat().format(new Date(card.time))}
                    {/* //{isNaN((new Date(card.time)).now())? new Date() :new Intl.DateTimeFormat().format(new Date(card.time))}
                     */}
                  </span>
                </Grid>
                <Grid item xs={12} className="feedCentre">
                  <Typography
                    variant="body1"
                    className="blue bold-700"
                    style={{ marginTop: "7px", marginBottom: "3px",cursor: "pointer" }}
                  >
                    {card.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="feedCentre">
                  <Box component="span" className="ratingBox" style={{fontSize:"11px", fontWeight:"100"}}>
                    {Images.STAR_ICON}
                    <Typography
                      variant="body1"
                      className="darker-gray bold-700"
                      style={{fontSize:"11px", fontWeight:"100"}}
                    >
                      {"4.56"}
                    </Typography>
                  </Box>
                </Grid>
                {index != userData?.length - 1 && (
                  <Divider
                    style={{
                      width: "50%",
                      alignself: "center",
                      marginTop: "5%",
                      marginBottom: "5%",
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default BioCard;
