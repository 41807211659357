import React, { useState } from "react";
import "./style.scss";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";

import UserAvt from "../../../assets/img/user1.png";
import FormProfile from "./FormProfile";
import ModalAvatar from "./ModalAvatar";
import UserDetail from "./UserDetail";
import Detail from "./detail";

const SettingProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div className="settingProfile">
      <UserDetail name="Bryan Guy" image={UserAvt} type="user" />

      {isEdit ? (
        <FormProfile onCancel={() => setIsEdit(false)} />
      ) : (
        <Detail
          data={[
            { label: "First Name", value: "Bryan" },
            { label: "Last Name", value: "Guy" },
            { label: "Phone Number", value: "604-999-9999" },
            { label: "Email Address", value: "bguy@databillity.com" },
            { label: "Title", value: "President/CEO" },
            { label: "Role", value: "Administrator" },
            { label: "Location", value: "Signature Mazda" },
            {
              label: "Personal LinkedIn",
              value: "linkedin.com/in/bryan-guy-13524397",
            },
          ]}
          onUpdate={() => setIsEdit(true)}
          onCancel={() => setIsEdit(false)}
          onDelete={() => setIsEdit(false)}
        />
      )}

      <ModalAvatar open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default SettingProfile;
