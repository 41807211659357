import { Form, Input, Row, Col, Button, Select } from "antd";
import "./form-profile.scss";

const FormOrganization = ({ isModal }) => {
  const handleFinish = (values) => {
    console.log(values);
  };

  return (
    <Form
      className="formOrganization"
      layout="vertical"
      onFinish={handleFinish}
    >
      <Row gutter={[54, 15]}>
        <Col md={12} xs={24}>
          <Form.Item
            label="Legal Name"
            name="legal_name"
            rules={[
              {
                required: true,
                message: "First name is required",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Website"
            name="website"
            rules={[
              {
                required: true,
                message: "Last name is required",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                message: "Phone number is required",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                type: "email",
                message: "Email invalid",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Company Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Company email is required",
              },
              {
                type: "email",
                message: "Email invalid",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Location" name="location">
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Legal Address"
            name="leagal_address"
            rules={[
              {
                required: true,
                message: "Legal Address is required",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="City/Town"
            name="city"
            rules={[
              {
                required: true,
                message: "City/Town is required",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Province/State"
            name="state"
            rules={[
              {
                required: true,
                message: "Province/State is required",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Postal Code/Zip Code"
            name="zipcode"
            rules={[
              {
                required: true,
                message: "Postal Code/Zip Code is required",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Entity Type"
            name="entity_type"
            rules={[
              {
                required: true,
                message: "Entity Type is required",
              },
            ]}
          >
            <Select
              options={[
                {
                  value: "Corporation",
                  label: "Corporation",
                },
                {
                  value: "Partnership",
                  label: "Partnership",
                },
                {
                  value: "LLC",
                  label: "LLC",
                },
                {
                  value: "Sole Proprietorship",
                  label: "Sole Proprietorship",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Industry"
            name="industry"
            rules={[
              {
                required: true,
                message: "Industry is required",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Company LinkedIn" name="linkedin">
            <Input placeholder="" />
          </Form.Item>
        </Col>

        <Col md={24}>
          {isModal ? (
            <div className="actions modalActions">
              <Button className="btnSave" type="primary" htmlType="submit">
                Add
              </Button>
            </div>
          ) : (
            <div className="actions">
              <Button className="btnCancel" htmlType="button">
                Cancel
              </Button>
              <Button className="btnSave" type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default FormOrganization;
