import React from "react";
import "./style.scss";
import moment from "moment";
import useWindowSize from "../../../hooks/useWindowSize";
import { Card, Button } from "antd";
import UserImg from "../../../assets/img/user1.png";

const desktopFormat = "HH:mm MM/DD/YYYY";
const mobileFormat = "MM/DD/YY H:mma";
const timeFormat = "H:mma";

const CustomerDataBox = ({
  customer,
  prevCustomer,
  onClick,
  isMobile,
  loading,
  index,
}) => {
  const { width } = useWindowSize();

  const convertTime = (time) => {
    let showTime = "";
    const _currentYear = moment().year();
    const _year = moment(time).year();
    const _currentMonth = moment().month();
    const _month = moment(time).month();
    const _currentWeek = moment().week();
    const _week = moment(time).week();
    const _currentDay = moment().date();
    const _day = moment(time).date();

    if (_currentYear > _year) {
      showTime = moment(time).format("MMM DD, YYYY");
    } else if (_currentMonth > _month) {
      showTime = moment(time).format("MMM D");
    } else if (_currentDay === _day) {
      showTime = moment(time).format("H:mm");
    } else if (_currentWeek === _week) {
      showTime = moment(time).format("dddd");
    } else if (_currentWeek > _week) {
      showTime = moment(time).format("MMM D");
    } else if (_currentDay - 1 === _day) {
      showTime = "Yesterday";
    } else {
      showTime = moment(time).format("MMM D");
    }

    return showTime;
  };

  const currentTime = convertTime(customer.time);
  const prevTime = convertTime(prevCustomer.time);

  return (
    <>
      {width < 1025 && currentTime !== prevTime && (
        <p className="timeline">{currentTime}</p>
      )}
      <Card className="customerDataBox customerMobile">
        <div className="customerHeader">
          <div className="avatar">
            <img alt="" src={UserImg} />
          </div>
          <div className="info">
            <h3>
              <span
                className={
                  index < 5 ? "active" : index < 10 ? "away" : "offline"
                }
              />{" "}
              {customer.name}
            </h3>
            <p
              className={`persona ${customer.persona
                .toLowerCase()
                .split(" ")
                .map((word) => {
                  return word.replace(/-/g, " ");
                })
                .join("-")}`}
            >
              {customer.persona}
            </p>
          </div>
          <div className="headerAction">
            <Button
              className="btnView"
              color="primary"
              shape="circle"
              onClick={onClick}
              size="small"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.6241 11.3038C23.9203 11.632 24.0842 12.0583 24.0842 12.5003C24.0842 12.9423 23.9203 13.3686 23.6241 13.6967C21.7491 15.7146 17.4813 19.6431 12.4991 19.6431C7.51699 19.6431 3.24913 15.7146 1.37413 13.6967C1.07799 13.3686 0.914062 12.9423 0.914062 12.5003C0.914062 12.0583 1.07799 11.632 1.37413 11.3038C3.24913 9.28599 7.51699 5.35742 12.4991 5.35742C17.4813 5.35742 21.7491 9.28599 23.6241 11.3038Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.4972 16.0716C14.4697 16.0716 16.0686 14.4726 16.0686 12.5001C16.0686 10.5277 14.4697 8.92871 12.4972 8.92871C10.5248 8.92871 8.92578 10.5277 8.92578 12.5001C8.92578 14.4726 10.5248 16.0716 12.4972 16.0716Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
            {/* <p className="status">Onsite</p>
          <p className="time">
            {customer.time
              ? moment(customer.time).format(
                  width > 576 ? desktopFormat : mobileFormat
                )
              : ""}
          </p> */}
          </div>
        </div>
        <div className="contentDetail">
          <p>New customer check-in.</p>

          <p className="time">
            {customer.time ? moment(customer.time).format(timeFormat) : ""}
          </p>
        </div>
        <div className="actions">
          <Button shape="rounded" type="primary">
            Recommendations
          </Button>
          <Button
            shape="circle"
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 10.5C3.675 10.5 3 11.175 3 12C3 12.825 3.675 13.5 4.5 13.5C5.325 13.5 6 12.825 6 12C6 11.175 5.325 10.5 4.5 10.5ZM19.5 10.5C18.675 10.5 18 11.175 18 12C18 12.825 18.675 13.5 19.5 13.5C20.325 13.5 21 12.825 21 12C21 11.175 20.325 10.5 19.5 10.5ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
                  fill="currentColor"
                />
              </svg>
            }
          />
        </div>
      </Card>
    </>
  );
};

export default CustomerDataBox;
