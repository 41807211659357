import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import "../../../pages/Reports/style.scss";
import StatCard from "../StatCard";
import Images from "../../../assets/img/ImgConstants";
import CustomersTable from "./CustomersTable";
import CustomerInsights from "../CustomerInsights";
import { useDispatch, useSelector } from "react-redux";
import { getIndividualsTableData } from "../../../redux/actions/insights";
import Banner from "./Banner";

const IndividualsTab = (props) => {
  const { startDate, endDate } = props;
  const [customerId, setCustomerId] = useState();
  const [individualsInsights, setIndividualsInsights] = useState();
  const [individualsListing, setIndividualsListing] = useState([]);
  const { insights, isLoading } = useSelector((state) => state.insights);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getIndividualsTableData({
        startDate: startDate,
        endDate: endDate,
      })
    );
  }, [startDate, endDate]);

  useEffect(() => {
    if (!isLoading) {
      setIndividualsListing(insights.individual.table);
    }
  }, [insights, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      setIndividualsInsights(insights.individual.customer);
    }
  }, [insights, isLoading]);

  return (
    <Box style={{ marginBottom: "40px" }}>
      {customerId ? (
        <CustomerInsights
          insightsData={individualsInsights}
          isLoading={isLoading}
        />
      ) : (
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} xs={12} sm={12}>
            <Banner/>
          </Grid>
          <Grid item xl={12} lg={12} xs={12} sm={12}>
            <CustomersTable
              startDate={startDate}
              endDate={endDate}
              setCustomerId={setCustomerId}
              individualsListing={individualsListing}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default IndividualsTab;
