import axios from "axios";
import {
  CREATE_CHECKIN_REQUEST,
  CREATE_CHECKIN_REQUEST_ERROR,
  CREATE_CHECKIN_REQUEST_SUCCESS,
} from "../constant/check-in";
import { userPool } from "../../utils/aws-config";
import { setNotification } from "./notification";
export const createCheckIn = (formdata) => async (dispatch) => {
  dispatch({
    type: CREATE_CHECKIN_REQUEST,
  });

  try {
    const databillityStr = localStorage.getItem("databillity");
    const databillity = databillityStr ? JSON.parse(databillityStr) : null;

    var cognitoUser = userPool.getCurrentUser();
    if (databillityStr) {
      const epochTime = Date.now()
      formdata = { ...formdata, databillityId: databillity.databillity_id, localCheckInTime: epochTime };
    }
    const response = await axios.post(
      "https://jz1ow9x3i7.execute-api.us-east-1.amazonaws.com/opt-in",
      formdata
    );
    dispatch({
      type: CREATE_CHECKIN_REQUEST_SUCCESS,
      payload: response.data,
    });

    dispatch(setNotification("success", response.data));

    // cognitoUser.getUserAttributes(function (err, result) {
    //   if (err) {
    //     alert(err.message || JSON.stringify(err));
    //     return;
    //   }
    //   // Find the 'email_verified' attribute in the result array
    //   const clientUUIDProp = result.find(
    //     (attr) => attr.getName() === "custom:client_uuid"
    //   );

    //   if (clientUUIDProp) {
    //     const clientUUIDValue = clientUUIDProp.getValue();
    //     console.log("client_uuid has value:", clientUUIDValue);
    //   } else {
    //     console.log("client_uuid attribute not found.");
    //   }
    // });
  } catch (error) {
    dispatch({
      type: CREATE_CHECKIN_REQUEST_ERROR,
      payload: error.message,
    });
  }
};
