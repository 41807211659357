export const GET_SUMMARYDATA_REQUEST = "GET_SUMMARYDATA_REQUEST"
export const GET_SUMMARYDATA_REQUEST_SUCCESS = "GET_SUMMARYDATA_REQUEST_SUCCESS"
export const GET_SUMMARYDATA_REQUEST_ERROR = "GET_SUMMARYDATA_LOAD_ERROR"

export const GET_ALL_CUSTOMERS_REQUEST = "GET_ALL_CUSTOMERS_REQUEST"
export const GET_ALL_CUSTOMERS_REQUEST_SUCCESS = "GET_ALL_CUSTOMERS_REQUEST_SUCCESS"
export const GET_ALL_CUSTOMERS_REQUEST_ERROR = "GET_ALL_CUSTOMERS_REQUEST_ERROR"

export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST"
export const GET_CUSTOMER_REQUEST_SUCCESS = "GET_CUSTOMER_REQUEST_SUCCESS"
export const GET_CUSTOMER_REQUEST_ERROR = "GET_CUSTOMER_REQUEST_ERROR"

export const GET_ASYNC_DATA = "GET_ASYNC_DATA"
export const GET_ASYNC_DATA_SUCCESS = "GET_ASYNC_DATA_SUCCESS"
export const GET_ASYNC_DATA_ERROR = "GET_ASYNC_DATA_ERROR"

export const UPDATE_RATES = "UPDATE_RATES"
export const UPDATE_RATES_SUCCESS = "UPDATE_RATES_SUCCESS"
export const UPDATE_RATES_ERROR = "UPDATE_RATES_ERROR"

export const GET_USER_RATE = "GET_USER_RATE"
export const GET_USER_RATE_SUCCESS = "GET_USER_RATE_SUCCESS"
export const GET_USER_RATE_ERROR = "GET_USER_RATE_ERROR"

export const CREATE_RATE = "CREATE_RATE"
export const CREATE_RATE_SUCCESS = "CREATE_RATE_SUCCESS"
export const CREATE_RATE_ERROR = "CREATE_RATE_ERROR"

export const GET_ALL_PERSONA = "GET_ALL_PERSONA"
export const GET_ALL_PERSONA_SUCCESS = "GET_ALL_PERSONA_SUCCESS"
export const GET_ALL_PERSONA_ERROR = "GET_ALL_PERSONA_ERROR"

export const UPDATE_PERSONA = "UPDATE_PERSONA"
export const UPDATE_PERSONA_SUCCESS = "UPDATE_PERSONA_SUCCESS"
export const UPDATE_PERSONA_ERROR = "UPDATE_PERSONA_ERROR"


export const RESET_STATE_CO_PILOT = "RESET_STATE_CO_PILOT"