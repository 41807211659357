import React, { useEffect, useState } from "react";
import CustomerInsights from "../CustomerInsights";
import { useDispatch, useSelector } from "react-redux";
import { getSegmentTabData } from "../../../redux/actions/insights";

const SegmentsTab = (props) => {
  const {startDate, endDate} = props;
  const { insights, isLoading } = useSelector((state) => state.insights);
  const [segmentsData, setSegmentsData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(getSegmentTabData({ startDate: startDate, endDate: endDate }));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!isLoading) {
      setSegmentsData(insights.segment);
    }
  }, [insights, isLoading]);

  return <CustomerInsights insightsData={segmentsData} isLoading={isLoading} />;
};

export default SegmentsTab;
