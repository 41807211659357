import React, { useState } from "react";
import FormOrganization from "./FormOrganization";
import "./style.scss";
import { Button } from "antd";
import UserDetail from "../profile/UserDetail";
import DefaultImg from "../../../assets/img/logo-closed.svg";
import Detail from "../profile/detail";
import ModalLocation from "./ModalLocation";

const SettingOrganization = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div className="settingOrganization">
      <div className="orgHeader">
        <UserDetail name="Databillity" image={DefaultImg} type="org" />

        <Button
          className="btnLocation"
          type="primary"
          onClick={() => setOpen(true)}
        >
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 7.42857H7.42857V13H5.57143V7.42857H0V5.57143H5.57143V0H7.42857V5.57143H13V7.42857Z"
              fill="white"
            />
          </svg>
          Location
        </Button>
      </div>
      {isEdit ? (
        <FormOrganization />
      ) : (
        <Detail
          data={[
            { label: "Legal Name", value: "Signature Mazda" },
            { label: "Website", value: "signaturemazda.com" },
            { label: "Phone Number", value: "604-999-9999" },
            { label: "Email Address", value: "info@signaturemazda.com" },
            { label: "Company Email", value: "info@signaturemazda.com" },
            {
              label: "Legal Address",
              value: "1234 Smallville Pl. Richmond, BC V7A 1A1",
            },
            { label: "Entity Type", value: "Corporation" },
            {
              label: "Industry",
              value: "Automotive",
            },
            {
              label: "Location",
              value: "Signature Mazda",
            },
            {
              label: "Company LinkedIn",
              value:
                "https://www.linkedin.com/company/signature-automotive-group",
            },
          ]}
          onUpdate={() => setIsEdit(true)}
          onCancel={() => setIsEdit(false)}
          onDelete={() => setIsEdit(false)}
        />
      )}

      <ModalLocation open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default SettingOrganization;
