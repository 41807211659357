import { useState, useEffect } from "react";
import "./style.scss";
import { Col, Row, Input, Button, Avatar, Tag, Table, Dropdown } from "antd";

import UserAvt from "../../../assets/img/user1.png";
import { EllipsisOutlined } from "@ant-design/icons";
import ModalInvite from "./ModalInvite";
import ModalUserDetail from "./ModalUserDetail";

const { Search } = Input;

const SettingUsers = () => {
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [detail, setDetail] = useState(null);
  const [data, setData] = useState([]);
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setDetail(null);
  };

  const menuItems = [
    {
      key: "1",
      label: "Show details",
    },
    {
      key: "2",
      label: "Revoke access",
    },
  ];

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "id",
      render: (id, record) => (
        <div className="viewUser">
          <Avatar size={35} src={UserAvt} />
          <div className="userInfo">
            <h4>
              {record?.first_name || ""} {record?.last_name || ""}{" "}
              {record?.role === "Administrator" && <Tag>USER ACCESS ADMIN</Tag>}
            </h4>
            <p>{record?.email || ""}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Last active",
      dataIndex: "lastActive",
      key: "lastActive",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 140,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: 160,
      // align:"right",
      render: (id, record) => {
        return (
          <div className="action">
            {[1, 3, 6].includes(id) ? (
              <Button type="text" className="btnInvite">
                Resend invite
              </Button>
            ) : (
              <Button
                type="text"
                className="btnInvite"
                onClick={() => {
                  setDetail(record);
                  setOpenDetail(true);
                }}
              >
                Show details
              </Button>
            )}

            <Dropdown
              menu={{ items: menuItems }}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button type="text" className="btnMenu">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="currentColor"
                  />
                </svg>
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const createData = () => {
    let results = [];
    for (let i = 0; i < 18; i++) {
      let obj = {
        id: i,
        // user: {
        //   name: `Lori Wunch ${i}`,
        //   email: `lwunch${i}@gmail.com`,
        //   role: i === 0 && "USER ACCESS ADMIN",
        // },
        first_name: "Lori",
        last_name: `Wunch ${i}`,
        email: `lwunch${i}@gmail.com`,
        phone: "123123123",
        role: i === 0 && "Administrator",
        lastActive: "Feb 07, 2024",
        status: "Invited",
        title: `Title ${i}`,
        location: "Signature Mazda",
      };
      results.push(obj);
    }

    setData(results);
  };

  useEffect(() => {
    createData();
  }, []);

  return (
    <div className="settingUsers">
      <div className="userHeader">
        <div className="stats">
          <p>Manage access for all the users in your organization. </p>

          <div className="viewStats">
            <div className="statItem">
              <h6>Total users</h6>
              <h2>14</h2>
            </div>

            <div className="statItem">
              <h6>Active users</h6>
              <h2>7</h2>
            </div>

            <div className="statItem">
              <h6>Administrator</h6>
              <h2>7</h2>
            </div>
          </div>

          <Search
            placeholder="Enter public name or email address"
            onSearch={onSearch}
            style={{
              width: 330,
            }}
            // enterButton={
            //   <svg
            //     width="17"
            //     height="17"
            //     viewBox="0 0 17 17"
            //     fill="none"
            //     xmlns="http://www.w3.org/2000/svg"
            //   >
            //     <path
            //       d="M16 16L12.2032 12.2032M12.2032 12.2032C12.8126 11.5938 13.296 10.8703 13.6258 10.0741C13.9556 9.2779 14.1254 8.42451 14.1254 7.56269C14.1254 6.70086 13.9556 5.84748 13.6258 5.05126C13.296 4.25503 12.8126 3.53157 12.2032 2.92217C11.5938 2.31276 10.8703 1.82936 10.0741 1.49955C9.2779 1.16975 8.42451 1 7.56269 1C6.70086 1 5.84748 1.16975 5.05126 1.49955C4.25503 1.82936 3.53157 2.31277 2.92217 2.92217C1.69142 4.15291 1 5.82216 1 7.56269C1 9.30322 1.69142 10.9725 2.92217 12.2032C4.15291 13.434 5.82216 14.1254 7.56269 14.1254C9.30322 14.1254 10.9725 13.434 12.2032 12.2032Z"
            //       stroke="#002857"
            //       stroke-width="2"
            //       stroke-linecap="round"
            //       stroke-linejoin="round"
            //     />
            //   </svg>
            // }
          />
        </div>

        <Button
          className="btnInvite"
          type="primary"
          onClick={() => setOpen(true)}
        >
          Invite users
        </Button>
      </div>

      <div>
        <Table columns={columns} dataSource={data} scroll={{ x: 800 }} />
      </div>

      <ModalInvite open={open} onClose={() => setOpen(false)} />
      <ModalUserDetail
        detail={detail}
        open={openDetail}
        onClose={handleCloseDetail}
      />
    </div>
  );
};

export default SettingUsers;
