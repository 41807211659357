import { React, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import {
  modalStyle,
  boolList,
  entityTypeList,
  countryList,
  industryList,
  numberOfLocationsList,
} from "../../../pages/Onboarding/constants.js";
import {
  scrollToTop,
  cleanPhoneNumber,
  formatPhoneNumber,
} from "../../../utils/utilities.js";
import Images from "../../../assets/img/ImgConstants.jsx";
import CancelModal from "../CancelModal/index.jsx";
import InformationIcon from "../../../assets/img/information-icon.svg";
import "./style.scss";
import { setCompanyInfo } from "../../../redux/actions/onboarding.js";

const CompanyInformation = (props) => {
  const { setBackClicked } = props;
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const { onboarding, isLoading, error } = useSelector(
    (state) => state.onboarding
  );
  useEffect(() => {
    scrollToTop();
  }, []);

  const [openCancel, setOpenCancel] = useState(false);
  const [openIndustryInfo, setOpenIndustryInfo] = useState(false);
  const [openLocationNumberModal, setOpenLocationNumberModal] = useState(false);

  const handleIndustryInfoClick = () => {
    setOpenIndustryInfo(true);
  };
  const handleCloseIndustryInfo = () => setOpenIndustryInfo(false);
  const handleLocationNumberInfoClick = () => {
    setOpenLocationNumberModal(true);
  };
  const handleCloseLocationNumberModal = () =>
    setOpenLocationNumberModal(false);

  const initialValues = {
    baseCountry: "",
    companyLegalName: "",
    doingBusinessName: "",
    isPublicCompany: "",
    entityType: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateProvince: "",
    zipCode: "",
    country: "",
    industry: "",
    phone: "",
    email: "",
    website: "",
    EIN: "",
    additionalLocations: "",
    numberOfLocations: "",
    location1: "",
    location1AddressLine1: "",
    location1AddressLine2: "",
    location1City: "",
    location1StateProvince: "",
    location1ZipCode: "",
    location1Country: "",
    location1Phone: "",
    location1Email: "",
    location1Website: "",
  };

  const validationSchema = yup.object().shape({
    baseCountry: yup.string().required("Please select an option"),
    companyLegalName: yup
      .string()
      .required("Please enter your Company's Legal Name")
      .matches(/\S/, "Invalid Company's Legal Name"),
    doingBusinessName: yup
      .string()
      .required("Please enter your Company's Business Name")
      .matches(/\S/, "Invalid Company's Business Name"),
    isPublicCompany: yup.string().required("Please select an option"),
    entityType: yup.string().required("Please select an option"),
    addressLine1: yup
      .string()
      .required("Please enter your Address")
      .matches(/\S/, "Invalid Address"),
    addressLine2: yup.string(),
    city: yup
      .string()
      .required("Please enter your City")
      .matches(/\S/, "Invalid City"),
    stateProvince: yup
      .string()
      .required("Please enter your State/Province")
      .matches(/\S/, "Invalid State/Province"),
    zipCode: yup
      .string()
      .required("Zip code is required")
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid zip code format"),
    country: yup.string().required("Please select your Country"),
    industry: yup.string().required("Please select an option"),
    phone: yup
      .string()
      .required("Please enter phone number")
      .min(14, "Phone must be at least 10 characters")
      .matches(/\S/, "Invalid phone"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    website: yup.string().url("Invalid URL"),
    EIN: yup.string(),

    additionalLocations: yup.string().required("Please select an option"),
    numberOfLocations: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("Please select an option"),
    }),
    location1: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("Location name is required"),
    }),
    location1AddressLine1: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("Address line 1 is required"),
    }),
    location1AddressLine2: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string(),
    }),
    location1City: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("City is required"),
    }),
    location1StateProvince: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("State/Province is required"),
    }),
    location1ZipCode: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () =>
        yup
          .string()
          .required("Zip code is required")
          .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid zip code format"),
    }),
    location1Country: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().required("Country is required"),
    }),
    location1Phone: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () =>
        yup
          .string()
          .required("Please enter phone number")
          .min(14, "Phone must be at least 10 characters")
          .matches(/\S/, "Invalid phone"),
    }),
    location1Email: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () =>
        yup
          .string()
          .required("Email is required")
          .email("Invalid email format"),
    }),
    location1Website: yup.string().when("additionalLocations", {
      is: (value) => value === "Yes",
      then: () => yup.string().url("Invalid website URL format"),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        setCompanyInfo({
          email: onboarding.account.email,
          company_based_location: values.baseCountry === "Yes",
          company_legal_name: values.companyLegalName,
          dba_name: values.doingBusinessName,
          public_company: values.isPublicCompany === "Yes",
          entity_type: values.entityType,
          address_line_1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          state_province: values.stateProvince,
          postal_code: values.zipCode,
          country: values.country,
          industry: values.industry,
          main_phone: cleanPhoneNumber(values.phone),
          main_email: values.email,
          website: values.website,
          ein: values.EIN,
          additional_locations: values.additionalLocations === "Yes",
          number_of_locations: parseInt(values.numberOfLocations, 10),
          location_name: values.location1,
          location_address_line_1: values.location1AddressLine1,
          location_address_line_2: values.location1AddressLine2,
          location_city: values.location1City,
          location_state_province: values.location1StateProvince,
          location_postal_code: values.location1ZipCode,
          location_country: values.location1Country,
          location_main_phone: cleanPhoneNumber(values.location1Phone),
          location_main_email: values.location1Email,
          location_website: values.location1Website,
        })
      );
    },
  });

  useEffect(() => {
    if (onboarding.companyInfo) {
      formik.setFieldValue(
        "baseCountry",
        Object.keys(onboarding.companyInfo).length !== 0
          ? onboarding.companyInfo.company_based_location
            ? "Yes"
            : "No"
          : ""
      );
      formik.setFieldValue(
        "companyLegalName",
        onboarding.companyInfo.company_legal_name
      );
      formik.setFieldValue(
        "doingBusinessName",
        onboarding.companyInfo.dba_name
      );
      formik.setFieldValue(
        "isPublicCompany",
        Object.keys(onboarding.companyInfo).length !== 0
          ? onboarding.companyInfo.public_company
            ? "Yes"
            : "No"
          : ""
      );
      formik.setFieldValue("entityType", onboarding.companyInfo.entity_type);
      formik.setFieldValue(
        "addressLine1",
        onboarding.companyInfo.address_line_1
      );
      formik.setFieldValue("addressLine2", onboarding.companyInfo.addressLine2);
      formik.setFieldValue("city", onboarding.companyInfo.city);
      formik.setFieldValue(
        "stateProvince",
        onboarding.companyInfo.state_province
      );
      formik.setFieldValue("zipCode", onboarding.companyInfo.postal_code);
      formik.setFieldValue("country", onboarding.companyInfo.country);
      formik.setFieldValue("industry", onboarding.companyInfo.industry);
      formik.setFieldValue(
        "phone",
        formatPhoneNumber(onboarding.companyInfo.main_phone)
      );
      formik.setFieldValue("email", onboarding.companyInfo.main_email);
      formik.setFieldValue("website", onboarding.companyInfo.website);
      formik.setFieldValue("EIN", onboarding.companyInfo.ein);
      formik.setFieldValue(
        "additionalLocations",
        Object.keys(onboarding.companyInfo).length !== 0
          ? onboarding.companyInfo.additional_locations
            ? "Yes"
            : "No"
          : ""
      );
      formik.setFieldValue(
        "numberOfLocations",
        onboarding.companyInfo.number_of_locations
      );
      formik.setFieldValue("location1", onboarding.companyInfo.location_name);
      formik.setFieldValue(
        "location1AddressLine1",
        onboarding.companyInfo.location_address_line_1
      );
      formik.setFieldValue(
        "location1AddressLine2",
        onboarding.companyInfo.location_address_line_2
      );
      formik.setFieldValue(
        "location1City",
        onboarding.companyInfo.location_city
      );
      formik.setFieldValue(
        "location1StateProvince",
        onboarding.companyInfo.location_state_province
      );
      formik.setFieldValue(
        "location1ZipCode",
        onboarding.companyInfo.location_postal_code
      );
      formik.setFieldValue(
        "location1Country",
        onboarding.companyInfo.location_country
      );
      formik.setFieldValue(
        "location1Phone",
        formatPhoneNumber(onboarding.companyInfo.location_main_phone)
      );
      formik.setFieldValue(
        "location1Email",
        onboarding.companyInfo.location_main_email
      );
      formik.setFieldValue(
        "location1Website",
        onboarding.companyInfo.location_website
      );
    }
  }, [onboarding]);

  const handleCancelButtonClick = () => {
    setBackClicked(true);
    setOpenCancel(true);
  };

  return (
    <Box className="infoForm">
      <Typography variant="h6" className="heading">
        Company Information
      </Typography>
      <Typography variant="body1" className="subHeading">
        Please provide all the information below
      </Typography>
      <FormikProvider value={formik}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Is your company based in the U.S. or Canada?
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.baseCountry ? "" : "Select"}
                </InputLabel>
                <Select
                  id="baseCountry"
                  name="baseCountry"
                  value={formik.values.baseCountry}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.baseCountry && formik.errors.baseCountry
                  )}
                >
                  {boolList.map((country, index) => (
                    <MenuItem
                      key={index}
                      value={country}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.errors.baseCountry && (
                <Typography variant="caption" color="error">
                  {formik.touched.baseCountry && formik.errors.baseCountry}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead mobileFieldLegal">
                Company legal name
              </Typography>
              <TextField
                id="companyLegalName"
                placeholder="ABC, Inc."
                variant="outlined"
                name="companyLegalName"
                value={formik.values && formik.values.companyLegalName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.companyLegalName &&
                    formik.errors.companyLegalName
                )}
                helperText={
                  formik.touched.companyLegalName &&
                  formik.errors.companyLegalName
                }
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Doing business as name
              </Typography>
              <TextField
                id="doingBusinessName"
                placeholder="ABC"
                variant="outlined"
                name="doingBusinessName"
                value={formik.values && formik.values.doingBusinessName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.doingBusinessName &&
                    formik.errors.doingBusinessName
                )}
                helperText={
                  formik.touched.doingBusinessName &&
                  formik.errors.doingBusinessName
                }
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Are you a public company?
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.isPublicCompany ? "" : "Select"}
                </InputLabel>
                <Select
                  id="isPublicCompany"
                  name="isPublicCompany"
                  value={formik.values.isPublicCompany}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.isPublicCompany &&
                      formik.errors.isPublicCompany
                  )}
                >
                  {boolList.map((isPublic, index) => (
                    <MenuItem
                      key={index}
                      value={isPublic}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {isPublic}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" color="error">
                {formik.touched.isPublicCompany &&
                  formik.errors.isPublicCompany}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography
                variant="body1"
                className="formHead mobileFieldEntity"
              >
                Entity type
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.entityType ? "" : "Select"}
                </InputLabel>
                <Select
                  id="entityType"
                  name="entityType"
                  value={formik.values.entityType}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.entityType && formik.errors.entityType
                  )}
                >
                  {entityTypeList.map((entity, index) => (
                    <MenuItem
                      key={index}
                      value={entity}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {entity}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" color="error">
                {formik.touched.entityType && formik.errors.entityType}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Company HQ located Address
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <TextField
                id="addressLine1"
                placeholder="Address Line 1"
                variant="outlined"
                name="addressLine1"
                value={formik.values && formik.values.addressLine1}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.addressLine1 && formik.errors.addressLine1
                )}
                helperText={
                  formik.touched.addressLine1 && formik.errors.addressLine1
                }
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <TextField
                id="addressLine2"
                placeholder="Address Line 2"
                variant="outlined"
                name="addressLine2"
                value={formik.values && formik.values.addressLine2}
                onChange={formik.handleChange}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <TextField
                id="city"
                placeholder="City"
                variant="outlined"
                name="city"
                value={formik.values && formik.values.city}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.city && formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <TextField
                id="stateProvince"
                placeholder="State/Province"
                variant="outlined"
                name="stateProvince"
                value={formik.values && formik.values.stateProvince}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.stateProvince && formik.errors.stateProvince
                )}
                helperText={
                  formik.touched.stateProvince && formik.errors.stateProvince
                }
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <TextField
                id="zipCode"
                placeholder="Postal Code/Zip Code"
                variant="outlined"
                name="zipCode"
                value={formik.values && formik.values.zipCode}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.country ? "" : "Country"}
                </InputLabel>
                <Select
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.country && formik.errors.country
                  )}
                >
                  {countryList.map((selectedCountry, index) => (
                    <MenuItem
                      key={index}
                      value={selectedCountry}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {selectedCountry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" color="error">
                {formik.touched.country && formik.errors.country}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="formItem">
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2 }}
                onClick={handleIndustryInfoClick}
              >
                <Typography variant="body1" className="formHead">
                  What industry does your company belong to?
                </Typography>
                <img
                  src={InformationIcon}
                  alt="Information"
                  style={{ height: "17px" }}
                />
              </Box>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.industry ? "" : "Select"}
                </InputLabel>
                <Select
                  id="industry"
                  name="industry"
                  value={formik.values.industry}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.industry && formik.errors.industry
                  )}
                >
                  {industryList.map((industryType, index) => (
                    <MenuItem
                      key={index}
                      value={industryType}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {industryType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" color="error">
                {formik.touched.industry && formik.errors.industry}
              </Typography>
            </Box>
            <Modal open={openIndustryInfo} onClose={handleCloseIndustryInfo}>
              <Box
                sx={{
                  ...modalStyle,
                  width: isMobile ? "80%" : isTablet ? "40%" : "30%",
                }}
              >
                <Button
                  onClick={handleCloseIndustryInfo}
                  sx={{ left: "50%", mb: 2 }}
                >
                  {Images.CLOSE_MODAL}
                </Button>
                <Typography variant="subtitle2" sx={{ mb: 4 }}>
                  DataBillity is suited for a specific type of business and
                  activities. If your business/activity is listed then
                  DataBillity is for you!
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 4 }}>
                  If your business/activity type is not listed, feel free to
                  reach out to our team to learn how DataBillity might be able
                  to provide value for your sales and marketing initiatives.
                </Typography>
              </Box>
            </Modal>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead mobileFieldPhone">
                Main Phone
              </Typography>
              <TextField
                id="phone"
                placeholder="999-999-9999"
                variant="outlined"
                name="phone"
                value={formik.values && formik.values.phone}
                onChange={(e) =>
                  formik.setFieldValue(
                    "phone",
                    formatPhoneNumber(e.target.value)
                  )
                }
                error={Boolean(formik.touched.phone && formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Main email address
              </Typography>
              <TextField
                id="email"
                placeholder="email@email.com"
                variant="outlined"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography
                variant="body1"
                className="formHead mobileFieldWebsite"
              >
                Website
              </Typography>
              <TextField
                id="website"
                placeholder="website.com"
                variant="outlined"
                name="website"
                value={formik.values && formik.values.website}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.website && formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Employer Identification Number (EIN)
              </Typography>
              <TextField
                id="EIN"
                placeholder="Optional"
                variant="outlined"
                name="EIN"
                value={formik.values && formik.values.EIN}
                onChange={formik.handleChange}
              />
            </Box>
          </Grid>
          <Grid item xs={formik.values?.additionalLocations === "Yes" ? 6 : 12}>
            <Box className="formItem">
              <Typography variant="body1" className="formHead">
                Does your company have additional locations?
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.additionalLocations ? "" : "Select"}
                </InputLabel>
                <Select
                  id="additionalLocations"
                  name="additionalLocations"
                  value={formik.values.additionalLocations}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.additionalLocations &&
                      formik.errors.additionalLocations
                  )}
                >
                  {boolList.map((location, index) => (
                    <MenuItem
                      key={index}
                      value={location}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="caption" color="error">
                {formik.touched.additionalLocations &&
                  formik.errors.additionalLocations}
              </Typography>
            </Box>
          </Grid>
          {formik.values?.additionalLocations === "Yes" ? (
            <>
              <Grid item xs={6}>
                <Box className="formItem">
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                    onClick={handleLocationNumberInfoClick}
                  >
                    <Typography
                      variant="body1"
                      className="formHead mobileFieldLocations"
                    >
                      How many locations?
                      <img
                        src={InformationIcon}
                        alt="Information"
                        style={{ marginLeft: "12px", height: "17px" }}
                      />
                    </Typography>
                  </Box>
                  <FormControl>
                    <InputLabel shrink={false}>
                      {formik.values.numberOfLocations ? "" : "Select"}
                    </InputLabel>
                    <Select
                      id="numberOfLocations"
                      name="numberOfLocations"
                      value={formik.values.numberOfLocations}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.numberOfLocations &&
                          formik.errors.numberOfLocations
                      )}
                    >
                      {numberOfLocationsList.map((number, index) => (
                        <MenuItem
                          key={index}
                          value={number}
                          sx={{ color: "#6F7174", fontWeight: "medium" }}
                        >
                          {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="caption" color="error">
                    {formik.touched.numberOfLocations &&
                      formik.errors.numberOfLocations}
                  </Typography>
                </Box>
                <Modal
                  open={openLocationNumberModal}
                  onClose={handleLocationNumberInfoClick}
                >
                  <Box
                    sx={{
                      ...modalStyle,
                      width: isMobile ? "80%" : isTablet ? "40%" : "30%",
                    }}
                  >
                    <Button
                      onClick={handleCloseLocationNumberModal}
                      sx={{ left: "50%", mb: 2 }}
                    >
                      {Images.CLOSE_MODAL}
                    </Button>
                    <Typography variant="subtitle2" sx={{ mb: 4 }}>
                      If you have more than 5 retail/event locations please
                      reach out to your Customer Success Manager or
                      email.tech@databillity.com to get help with a bulk upload.
                    </Typography>
                  </Box>
                </Modal>
              </Grid>
              <Grid item xs={12}>
                <Box className="formItem">
                  <Typography variant="body1" className="formHead">
                    Location Name
                  </Typography>
                  <TextField
                    id="location1"
                    placeholder="Location 1"
                    variant="outlined"
                    name="location1"
                    value={formik.values && formik.values.location1}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.location1 && formik.errors.location1
                    )}
                    helperText={
                      formik.touched.location1 && formik.errors.location1
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="formItem">
                  <Typography variant="body1" className="formHead">
                    Location Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <TextField
                    id="location1AddressLine1"
                    placeholder="Address Line 1"
                    variant="outlined"
                    name="location1AddressLine1"
                    value={formik.values && formik.values.location1AddressLine1}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.location1AddressLine1 &&
                        formik.errors.location1AddressLine1
                    )}
                    helperText={
                      formik.touched.location1AddressLine1 &&
                      formik.errors.location1AddressLine1
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <TextField
                    id="location1AddressLine2"
                    placeholder="Address Line 2"
                    variant="outlined"
                    name="location1AddressLine2"
                    value={formik.values && formik.values.location1AddressLine2}
                    onChange={formik.handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <TextField
                    id="location1City"
                    placeholder="City"
                    variant="outlined"
                    name="location1City"
                    value={formik.values && formik.values.location1City}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.location1City &&
                        formik.errors.location1City
                    )}
                    helperText={
                      formik.touched.location1City &&
                      formik.errors.location1City
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <TextField
                    id="location1StateProvince"
                    placeholder="State/Province"
                    variant="outlined"
                    name="location1StateProvince"
                    value={
                      formik.values && formik.values.location1StateProvince
                    }
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.location1StateProvince &&
                        formik.errors.location1StateProvince
                    )}
                    helperText={
                      formik.touched.location1StateProvince &&
                      formik.errors.location1StateProvince
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <TextField
                    id="location1ZipCode"
                    placeholder="Postal Code/Zip Code"
                    variant="outlined"
                    name="location1ZipCode"
                    value={formik.values && formik.values.location1ZipCode}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.location1ZipCode &&
                        formik.errors.location1ZipCode
                    )}
                    helperText={
                      formik.touched.location1ZipCode &&
                      formik.errors.location1ZipCode
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <FormControl>
                    <InputLabel shrink={false}>
                      {formik.values.location1Country ? "" : "Country"}
                    </InputLabel>
                    <Select
                      id="location1Country"
                      name="location1Country"
                      value={formik.values.location1Country}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.location1Country &&
                          formik.errors.location1Country
                      )}
                    >
                      {countryList.map((selectedCountry, index) => (
                        <MenuItem
                          key={index}
                          value={selectedCountry}
                          sx={{ color: "#6F7174", fontWeight: "medium" }}
                        >
                          {selectedCountry}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="caption" color="error">
                    {formik.touched.location1Country &&
                      formik.errors.location1Country}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <Typography
                    variant="body1"
                    className="formHead mobileFieldPhone"
                  >
                    Main Phone
                  </Typography>
                  <TextField
                    id="location1Phone"
                    placeholder="999-999-9999"
                    variant="outlined"
                    name="location1Phone"
                    value={formik.values && formik.values.location1Phone}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "location1Phone",
                        formatPhoneNumber(e.target.value)
                      )
                    }
                    error={Boolean(
                      formik.touched.location1Phone &&
                        formik.errors.location1Phone
                    )}
                    helperText={
                      formik.touched.location1Phone &&
                      formik.errors.location1Phone
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <Typography variant="body1" className="formHead">
                    Main email address
                  </Typography>
                  <TextField
                    id="location1Email"
                    placeholder="email@email.com"
                    variant="outlined"
                    name="location1Email"
                    value={formik.values.location1Email}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.location1Email &&
                        formik.errors.location1Email
                    )}
                    helperText={
                      formik.touched.location1Email &&
                      formik.errors.location1ZipCode
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="formItem">
                  <Typography variant="body1" className="formHead">
                    Website
                  </Typography>
                  <TextField
                    id="location1Website"
                    placeholder="website.com"
                    variant="outlined"
                    name="location1Website"
                    value={formik.values && formik.values.location1Website}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.location1Website &&
                        formik.errors.location1Website
                    )}
                    helperText={
                      formik.touched.location1Website &&
                      formik.errors.location1Website
                    }
                  />
                </Box>
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <Box className="formButtons">
              <Button variant="outlined" onClick={handleCancelButtonClick}>
                Back
              </Button>
              <Button variant="contained" onClick={formik.handleSubmit}>
                Next
              </Button>
              <CancelModal open={openCancel} setOpen={setOpenCancel} />
            </Box>
          </Grid>
        </Grid>
      </FormikProvider>
    </Box>
  );
};

export default CompanyInformation;
