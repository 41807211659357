import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { resendCode, verifyOTP } from "../../../redux/actions/onboarding";
import Footer from "../../Layout/Footer";
import ClosedLogo from "../../../assets/img/logo-closed.svg";
import InfoIcon from "@mui/icons-material/Info";
import "./style.scss";

const Authenticate = () => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onboarding, isLoading, error } = useSelector(
    (state) => state.onboarding
  );

  const [email, setEmail] = useState("bacdefgh@gmail.com");
  const [maskedEmail, setMaskedEmail] = useState("");

  useEffect(() => {
    if (!isLoading && onboarding.account.email !== null) {
      setEmail(onboarding.account.email);
    }
    if (email) {
      const emailParts = email.split("@");
      if (emailParts.length > 1) {
        setMaskedEmail(
          emailParts[0].charAt(0) +
            "*".repeat(emailParts[0].length - 1) +
            "@" +
            emailParts[1]
        );
      }
    }
  }, [isLoading, email, onboarding.account]);

  const [otp, setOtp] = useState("");
  const handleChangeOTP = (event) => {
    if (isMobile) {
      const inputValue = event.target.value;
      setOtp(inputValue);
    } else setOtp(event);
  };

  const [isFocused, setIsFocused] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (otp.length === 6) {
      setIsOtpValid(true);
    } else {
      setIsOtpValid(false);
    }
  }, [otp]);

  useEffect(() => {
    if (onboarding.account.verified)
      navigate("/onboarding");
  }, [onboarding.account.verified]);

  const handleSubmitCode = () => {
    dispatch(verifyOTP({ email: email, code: otp }));
  };

  const handleResendCode = () => {
    dispatch(resendCode({ email: email }));
  };

  return (
    <Container maxWidth="xxl" className="authenticate">
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box className="authContainer">
            <Box className="desktopLogoContainer">
              <img
                src={ClosedLogo}
                alt="Desktop Logo"
                className="desktopLogo"
              />
            </Box>
            <Box className="authCard">
              <Box className="innerBox">
                <Typography variant="h6" className="cardHeading">
                  {isMobile
                    ? "2-step verification"
                    : "Authenticate Your Account"}
                </Typography>
                <Typography variant="body1" className="cardSubHeading">
                  {isMobile
                    ? "Enter the 2-step verification code we emailed you"
                    : "Protecting your data is our top priority. Please confirm your account by entering the authorization code sent to"}
                </Typography>
                {!isMobile && (
                  <Typography variant="body1" className="cardEmail">
                    {maskedEmail}
                  </Typography>
                )}
              </Box>
              <Box className="otpBox">
                {isMobile ? (
                  <Box>
                    <Typography
                      variant="body2"
                      className={`codeFieldText ${isFocused ? "focused" : ""}`}
                    >
                      Code
                    </Typography>
                    <TextField
                      id="code"
                      variant="outlined"
                      color="secondary"
                      placeholder="6-digit code from email"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <InfoIcon />
                          </InputAdornment>
                        ),
                      }}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={handleChangeOTP}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Box>
                ) : (
                  <MuiOtpInput
                    autoFocus
                    length={6}
                    value={otp}
                    onChange={handleChangeOTP}
                    validateChar={() => true}
                    TextFieldsProps={{ variant: "standard" }}
                  />
                )}
              </Box>
              <Box className="cardLowerSection">
                {!isMobile && (
                  <Typography variant="caption">
                    It may take a minute to receive your code.
                    <br /> Haven't received it?{" "}
                    <span className="highlight" onClick={handleResendCode}>
                      Resend a new code
                    </span>
                  </Typography>
                )}
                <Button
                  variant="contained"
                  onClick={handleSubmitCode}
                  disabled={!isOtpValid}
                >
                  Submit
                </Button>
                {isMobile && <Button variant="outlined">I need help</Button>}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box style={{position: "absolute", width: "100%", bottom:"0px"}}>
            <Footer />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Authenticate;
