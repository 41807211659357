import React, { useEffect } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";

import Footer from "../../components/Layout/Footer";
import LogoDesktop from "../../assets/img/logo-desktop.svg";

import { useDispatch, useSelector } from "react-redux";
import { optOutConfirm } from "../../redux/actions/opt-out";

const OptOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, optOut } = useSelector((state) => state.optOut);
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    if (values.email) {
      dispatch(optOutConfirm({ email: values.email }));
    }
  };

  useEffect(() => {
    if (optOut) {
      navigate("/opt-out/confirm");
    }
  }, [optOut]);

  return (
    <div className="optOut">
      <div className="optOut-logoPage">
        <img src={LogoDesktop} alt="Logo" />
      </div>
      <div className="optOut-content">
        <div className="optOut-content-detail">
          <h4 className="optOut-content-detail-title">
            Unsubscribe/Disconnect from the network
          </h4>
          <Form layout="vertical" form={form} onFinish={handleFinish}>
            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: "Email is required!",
                },
                {
                  type: "email",
                  message: "Email invalid",
                },
              ]}
            >
              <Input placeholder="name@company.com" />
            </Form.Item>

            <Form.Item>
              <Button
                className="optOut-content-detail-button"
                loading={loading}
                block
                htmlType="submit"
              >
                Unsubscribe / Disconnect
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OptOut;
