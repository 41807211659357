import { Box, Typography, Chip, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import Images from "../../../assets/img/ImgConstants";
import "../../../pages/Reports/style.scss";
import "./style.scss";

const StatCard = (props) => {
  const {
    title,
    amount,
    percent,
    extra,
    color,
    icon,
    arrow,
    showInfo,
    isLoading,
  } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(isLoading);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  const formatAmount = (value) => {
    const hasDecimalParts = value % 1 !== 0;
    const minimumFractionDigits = hasDecimalParts ? 2 : 0;
    const maximumFractionDigits = hasDecimalParts ? 2 : 0;
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  };

  const skeletonCard = () => {
    return (
      <Box className="segmentStatsCard">
        <Box className="cardUpperSection">
          <Skeleton
            variant="circular"
            className="iconBox skeletonAvatar"
            active
          />
          <Box>
            <Typography
              variant="body1"
              className="dark-gray bold-500"
              style={{ marginBottom: "2px" }}
            >
              <Skeleton className="skeletonHeading" active />
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "1.35rem", color: "#002857" }}
            >
              <Skeleton className="skeletonHeading" active />
            </Typography>
          </Box>
          <Skeleton className="skeletonChip percentageChip" active />
        </Box>
        <Box className="cardLowerSection">
          <Typography variant="subtitle2" className="dark-gray">
            <Skeleton className="skeletonText" active />
          </Typography>
          {showInfo && Images.INFORMATION_ICON}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {loading ? (
        skeletonCard()
      ) : (
        <Box className="segmentStatsCard">
          <Box className="cardUpperSection">
            <Box className="iconBox">{icon}</Box>
            <Box>
              <Typography
                variant="body1"
                className="dark-gray bold-500"
                style={{ marginBottom: "2px" }}
              >
                {title}
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "1.35rem", color: "#002857" }}
              >
                ${formatAmount(amount)}
              </Typography>
            </Box>
            <Chip
              icon={arrow}
              label={`${formatAmount(percent)}%`}
              variant="filled"
              size="small"
              className={`percentageChip ${color}`}
            />
          </Box>
          <Box className="cardLowerSection">
            <Typography variant="subtitle2" className="dark-gray">
              You made an extra{" "}
              <span className="light-blue">${formatAmount(extra)}</span> this
              month
            </Typography>
            {showInfo && Images.INFORMATION_ICON}
          </Box>
        </Box>
      )}
    </>
  );
};

export default StatCard;
