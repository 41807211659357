import SettingOrganization from "../../components/settings/organization";
import SettingMetrics from "../../components/settings/metrics";
import SettingNotifications from "../../components/settings/notifications";
import SettingProfile from "../../components/settings/profile";
import SettingUsers from "../../components/settings/users";
import "./style.scss";
import { Tabs } from "antd";


const Settings = () => {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Profile",
      children: <SettingProfile />,
    },
    {
      key: "2",
      label: "Users",
      children: <SettingUsers />,
    },
    {
      key: "3",
      label: "Organization",
      children: <SettingOrganization />,
    },
    {
      key: "4",
      label: "Notifications",
      children: <SettingNotifications />,
    },
    {
      key: "5",
      label: "Metrics",
      children: <SettingMetrics />,
    },
  ];

  return (
    <div className="settingsPage">
      <h1 className="title">Settings</h1>

      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};

export default Settings;
