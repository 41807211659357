import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Container, Grid, Box } from "@mui/material";
import LogoDesktop from "../../assets/img/logo-desktop.svg";
import LoginGraphic from "../../assets/img/login-desktop-graphic.svg";
import "./style.scss";
import LoginForm from "../../components/Login/LoginForm";
import Footer from "../../components/Layout/Footer";
import Verification from "../../components/Login/Verification";
import NewPassword from "../../components/Login/NewPassword";
import ForgotPassword from "../../components/Login/ForgotPassword";
import { clearStore } from "../../redux/actions/auth";


const Login = () => {
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, email, user, newPassword, loading, error } =
    useSelector((state) => state.auth);
  const { onboarding, isLoading } = useSelector((state) => state.onboarding);

  // useEffect(() => {
  //   if (user) {
  //     console.log('User data:', user);
  //     // Perform additional actions with user data
  //   }
  // }, [user]);

  useEffect(() => {
    localStorage.clear();
  }, []);
  useEffect(() => {
    // Clear the Redux store when the component mounts
    dispatch(clearStore());
  }, [dispatch]);
  useEffect(() => {
    if (isAuthenticated) {
      if (onboarding.onboardingStep !== 3) navigate("/onboarding");
      else navigate("/dashboard");
    }
  }, [onboarding.onboardingStep]);

  const [step, setStep] = useState(0);
  const handleForgotPassword = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  const clientId = process.env.REACT_APP_AWS_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI_LOGIN_STAGING;
  const responseType = process.env.REACT_APP_RESPONSE_TYPE;
  const scope = "openid profile email";

  const loginUrlGoogle = `${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&identity_provider=Google`;
  const loginUrlMicrosoft = `${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&identity_provider=Microsoft`;

  function handleGoogleSignIn() {
    localStorage.clear();
  sessionStorage.clear();

  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  const state = Math.random().toString(36).substring(2, 15);
  const loginUrlGoogle = `${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&state=${state}&prompt=select_account`;

  // First, logout from Google
  const googleLogoutUrl = 'https://www.google.com/accounts/Logout';
  
  // Create a hidden iframe to trigger Google logout
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = googleLogoutUrl;
  document.body.appendChild(iframe);

  // Wait a bit to ensure logout is processed, then redirect to your login URL
  setTimeout(() => {
    document.body.removeChild(iframe);
    window.location.href = loginUrlGoogle;
  }, 2000);
  
  }
  function handleMicrosoftSignIn() {
    window.location.href = loginUrlMicrosoft;
  }

  const handleConnectQuickBooks = () => {
    console.log(
      "process.env.REACT_APP_URL_LOGIN_QUICKBOOKS: ",
      process.env.REACT_APP_URL_LOGIN_QUICKBOOKS
    );
    // makeTokenRequest();
    window.location.href = process.env.REACT_APP_URL_LOGIN_QUICKBOOKS || "";
    // "https://databillity.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=33f70nelt1vn95nidlc0lci5qc&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fapp.databillity.com%2Flogin%2F";
  };

  return (
    <Container maxWidth="xxl" className="login">
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box className="loginContainer">
            <Box className="loginCard">
              <Grid container>
                <Grid item xl={5}>
                  <Box className="loginCardLeftSide">
                    <Box className="loginLogo">
                      <img src={LogoDesktop} alt="logo desktop" />
                    </Box>
                    {step === 0 ? (
                      <LoginForm
                        handleForgotPassword={handleForgotPassword}
                        error={error}
                        loading={loading}
                        handleGoogleSignIn={handleGoogleSignIn}
                        handleMicrosoftSignIn={handleMicrosoftSignIn}
                      />
                    ) : step === 1 ? (
                      <ForgotPassword
                        handleForgotPassword={handleForgotPassword}
                        email={email}
                        error={error}
                        loading={loading}
                        handleGoogleSignIn={handleGoogleSignIn}
                        handleMicrosoftSignIn={handleMicrosoftSignIn}
                        setStep={setStep}
                      />
                    ) : step === 2 ? (
                      <Verification
                        handleForgotPassword={handleForgotPassword}
                        email={email}
                        newPassword={newPassword}
                        error={error}
                        loading={loading}
                      />
                    ) : step === 3 ? (
                      <NewPassword
                        email={email}
                        newPassword={newPassword}
                        error={error}
                        loading={loading}
                        setStep={setStep}
                      />
                    ) : null}
                  </Box>
                </Grid>
                {!isTablet && !isMobile && (
                  <Grid item xl={7}>
                    <Box className="loginCardRightSide">
                      <img
                        src={LoginGraphic}
                        alt="login graphic"
                        className="loginGraphic"
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box style={{ position: "absolute", width: "100%", bottom: "0px" }}>
            <Footer />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;

//  Your username is Kata1 and temporary password is 8ENr6fD?.
