export const segmentQuery = `
query GetSegmentTabData($startDate: String!, $endDate: String!, $databillityId: String!) {
  segmentTabQuery(startDate: $startDate, endDate: $endDate, databillityId: $databillityId) {
    customerLTV {
      amount
      extraAmount
      percentage
    },
    upSell {
      amount
      extraAmount
      percentage
    },
    crossSell {
      amount
      extraAmount
      percentage
    },
    cltv {
      amount
      percentage
    },
    ratingMatrics{
      avgStarRating
      totalRating
      totalUsers
    },
    segmentBio {
      name
      rating
      email
      phone
      img
    },
    salesChart {
      amount
    },
    chart {
      year
      sales
    },
    salesLift {
      amount
      percentage
    },
    averageOrderValue {
      amount
    },
    customerAcquisitionCost {
      amount
      percentage
    },
    timing {
      avgTime
      medianTime
    },
    productAffinity {
      name
      description
      progressBarValue
      price
    },
    topSellers {
      name
      description
      percentage
    }
  }
}`;

export const individualsQuery = `
query GetIndividualsTabData($customerId: String!, $startDate: String!, $endDate: String!) {
  individualsTabQuery(customerId: $customerId, startDate: $startDate, endDate: $endDate) {
    customerLTV {
      amount
      extraAmount
      percentage
    },
    cltv{
      amount
      percentage
    },
    crossSell {
      amount
      extraAmount
      percentage
    },
    upSell {
      amount
      extraAmount
      percentage
    },
    ratingMatrics{
      avgStarRating
      totalRating
      totalUsers
    },

    segmentBio {
      name
      rating
      email
      phone
      img
    },
     salesChart {
      amount
    },
    chart {
      year
      sales
    },
    salesLift {
      amount
      percentage
    },
    averageOrderValue {
      amount
    },
    customerAcquisitionCost {
      amount
      percentage
    },
    timing {
      avgTime
      medianTime
    },
    productAffinity {
      name
      description
      progressBarValue
      price
    },
    topSellers {
      name
      description
      percentage
    }
  }
}`;

export const individualsListQuery = `
  query GetIndividualsTableList($databillityId: String!, $startDate: String!, $endDate: String!) {
    individualsTableListQuery(databillityId: $databillityId, startDate: $startDate, endDate: $endDate) {
      individualsCustomerList {
        id
        date
        name
        status
        persona
        rating
        activity
        transaction
      }
    }
  }
`;
