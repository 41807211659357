import { Modal, Form, Select, Input, Button, Row, Col } from "antd";
import FormProfile from "../profile/FormProfile";
import "./style.scss";
import { useEffect } from "react";

const ModalUserDetail = ({ detail, open, onClose }) => {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    console.log(values);
  };

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        first_name: detail?.first_name,
        last_name: detail?.last_name,
        email: detail?.email,
        phone: detail?.phone,
        title: detail?.title,
        role: detail?.role,
        location: detail?.location,
      });
    }
  }, [detail]);

  return (
    <Modal
      wrapClassName="modalInvite modalCreateUser"
      open={open}
      onCancel={onClose}
      title=""
      footer={null}
    >
      <h3 className="title">
        {detail ? "Account detail" : "Create your account"}
      </h3>

      <Form
        form={form}
        className="formProfile"
        layout="vertical"
        onFinish={handleFinish}
      >
        <Row gutter={[54, 15]}>
          <Col md={12}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Last name is required",
                },
              ]}
            >
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Title" name="title">
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Role" name="role">
              <Select
                options={[
                  {
                    value: "Administrator",
                    label: "Administrator",
                  },
                  {
                    value: "Manager",
                    label: "Manager",
                  },
                  {
                    value: "Standard",
                    label: "Standard",
                  },
                ]}
                suffixIcon={
                  <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                      fill="#002857"
                    />
                  </svg>
                }
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone number is required",
                },
              ]}
            >
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  type: "email",
                  message: "Email invalid",
                },
              ]}
            >
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Location" name="location">
              <Select
              mode="multiple"
                options={[
                  {
                    value: "Signature Mazda",
                    label: "Signature Mazda",
                  },
                  {
                    value: "Richmond Centre",
                    label: "Richmond Centre",
                  },
                  {
                    value: "Byrne Road",
                    label: "Byrne Road",
                  },
                  {
                    value: "Lougheed Highway",
                    label: "Lougheed Highway",
                  },
                ]}
                suffixIcon={
                  <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                      fill="#002857"
                    />
                  </svg>
                }
              />
            </Form.Item>
          </Col>
          <Col md={24}>
            <div className="actions">
              <Button className="btnSave" type="primary" htmlType="submit">
                Send
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalUserDetail;
