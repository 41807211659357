import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Typography, Box, Skeleton, Button } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import "../../../../pages/Reports/style.scss";
import "./style.scss";

const ChartCard = (props) => {
  const { chartData, salesAmount, isLoading } = props;
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(isLoading);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  const months = [
    new Date(2023, 0),
    new Date(2023, 1),
    new Date(2023, 2),
    new Date(2023, 3),
    new Date(2023, 4),
    new Date(2023, 5),
    new Date(2023, 6),
    new Date(2023, 7),
    new Date(2023, 8),
    new Date(2023, 9),
    new Date(2023, 10),
    new Date(2023, 11),
  ];
  const monthFormatter = new Intl.DateTimeFormat("en-US", { month: "short" });

  const colors = ["#002857", "#C0EBFB"];

  const totalSales = chartData?.reduce((total, data) => {
    return total + (data?.sales?.reduce((sum, value) => sum + value, 0) ?? 0);
  }, 0);

  const formatAmount = (value) => {
    const hasDecimalParts = value % 1 !== 0;
    const minimumFractionDigits = hasDecimalParts ? 2 : 0;
    const maximumFractionDigits = hasDecimalParts ? 2 : 0;
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  };


  const skeletonCard = () => {
    return (
      <Box className="chartCard skeletonCard">
        <Box className="chartHeader">
          <Typography variant="h6">
            <Skeleton className="skeletonHeading" active />
          </Typography>
        </Box>
        <Box className="chartHeader" sx={{ paddingTop: "0px !important" }}>
          <Skeleton className="skeletonChart" active />
        </Box>
      </Box>
    );
  };

  return (
    <>
      {(loading || chartData.length === 0) ? (
        skeletonCard()
      ) : (
        <Box className="chartCard">
          <Box className="chartHeader">
            <Typography variant="h6" className="blue">
              Sales
            </Typography>
            <Box className="legendBox">
              {colors.map((color) => (
                <>
                  <Box
                    className="circle"
                    sx={{ backgroundColor: `${color}` }}
                  ></Box>
                  <Typography variant="subtitle2" className="gray">
                    {chartData[0]?.year}
                  </Typography>
                </>
              ))}
              <Button>
                <MoreHorizOutlinedIcon sx={{ color: "#002857" }} />
              </Button>
            </Box>
          </Box>
          <Typography variant="h6" className="chartAmount">
            ${salesAmount ?? formatAmount(totalSales)}
          </Typography>
          <Box className="chartCardBody">
            <LineChart
            height={200}
            leftAxis={null}
              bottomAxis={{
                disableLine: true,
                disableTicks: true,
              }}
              margin={{
                top: 0,
                right: isMobile ? 10 : -5,
                bottom: 45,
                left: isMobile ? 10 : -5,
              }}
              // sx={{
              //   [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 2 },
              // }}
              xAxis={[
                {
                  id: "Months",
                  data: months,
                  scaleType: "band",    //time  //linear
                  valueFormatter: (date) => monthFormatter.format(date),
                },
              ]}
              series={[
                {
                  id: chartData[0]?.year,
                  label: `${chartData[0]?.year}`,
                  data: chartData[0]?.sales,
                  color: colors[0],
                  stack: "total",
                  area: true,
                  showMark: false,
                },
                {
                  id: chartData[1]?.year,
                  label: `${chartData[1]?.year}`,
                  data: chartData[1]?.sales,
                  color: colors[1],
                  stack: "total",
                  area: true,
                  showMark: false,
                },
              ]}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChartCard;
