export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 20,
  p: "28px",

  borderRadius: "10px",
  color: "#002857",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
export const steps = [
  {
    label: "Basic information",
    description: `Completing your profile `,
  },
  {
    label: "Company information",
    description: "Details on our company and loaction(s)",
  },
  {
    label: "Choose a plan",
    description: `Plans right-sized for your business`,
  },
];

export const jobFunctionList = [
  "Advertising",
  "Sales",
  "Marketing",
  "Data Science",
  "Payments",
  "Finance",
  "Corporate Strategy",
  "Other",
];
export const accountRoleList = [
  "Administrator",
  "Manager",
  "Standard User",
  "View Only",
];
export const databilityUseOptionsList = [
  { value: "dataScience", label: "Data Science" },
  { value: "customerJourneys", label: "Tracking Customer Journeys" },
  { value: "leadGeneration", label: "Lead Generation" },
  { value: "onlineRecommendations", label: "Online Recommendations" },
  { value: "inStoreRecommendations", label: "In-Store Recommendations" },
];

export const boolList = ["Yes", "No"];
export const entityTypeList = [
  "Corporation",
  "LLC",
  "Partnership",
  "Sole Proprietorship",
];
export const countryList = [
  "Canada",
  "United States",
  "United Kingdom",
  "Australia",
  "Germany",
  "France",
  "India",
  "Italy",
  "Japan",
  "Netherlands",
  "Spain",
  "Sweden",
  "Switzerland",
];
export const industryList = [
  "Digital Advertising",
  "Digital Marketing",
  "E-Commerce Retail",
  "In-Person Retail",
  "Fintech",
  "Hotel Operator",
  "Dine-In Restaurant",
  "Sports & Entertainment",
  "Events",
];
export const numberOfLocationsList = ["1", "2", "3", "4", "5"];

export const PlanList = [
  {
    id: 1,
    title: "Free",
    price: 0,
    detail: "Ideal for solo operators and business with one location",
    points: [
      "5 seats",
      "500 profile views",
      "3 data connections",
      "Payment integration",
      "100  recommendations",
      "POS app",
      "Quickbooks app",
    ],
  },
  {
    id: 2,
    title: "Business",
    price: 199,
    detail: "Right-sized for multi-location and omni channel businesses",
    points: [
      "5 seats",
      "2500 profile views",
      "20 data connections",
      "Advanced predictive insights",
      "1,000 recommendations",
      "Real-time sales features",
      "Account manager",
      "Stripe app",
      "Salesforce app",
    ],
  },
  {
    id: 3,
    title: "Premium",
    price: 799,
    detail: "Perfect  fit for growth oriented companies",
    points: [
      "5 seats",
      "Unlimited profile views",
      "Unlimited data connections",
      "2,500 recommendations",
      "AI augmentation",
      "Stripe app",
      "Salesforce app",
    ],
  },
  {
    id: 4,
    title: "Enterprise",
    detail: "Deploy your own instance of Databillity",
    points: "Unlimited potential",
  },
];

export const InfoIconList = [
  "100  recommendations",
  "20 data connections",
  "Advanced predictive insights",
  "1,000 recommendations",
  "2,500 recommendations",
  "AI augmentation",
];
