import React, { useEffect, useState } from "react";
import { Typography, Box, Pagination, Skeleton } from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CardSeatImg from "../../../../assets/img/carSeatImg.png";
import "../../../../pages/Reports/style.scss";
import "./style.scss";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#0050BC" : "#308fe8",
  },
}));

const ProductAffinity = (props) => {
  const { productAffinity, isLoading } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(isLoading);
    }, 2000);

    return () => clearTimeout(timer); //
  }, [isLoading]);

  const formatAmount = (value) => {
    const hasDecimalParts = value % 1 !== 0;
    const minimumFractionDigits = hasDecimalParts ? 2 : 0;
    const maximumFractionDigits = hasDecimalParts ? 2 : 0;
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  };

  const ItemsPerPage = 4;
  const totalPages = Math.ceil(productAffinity?.length / ItemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * ItemsPerPage;
  const endIndex = startIndex + ItemsPerPage;
  const currentItems = productAffinity?.slice(startIndex, endIndex);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const skeletonRows = () => {
    let results = [];
    for (let i = 0; i < 4; i++) {
      results.push(
        <Box className="productAffinityBody">
          <Box className="productAffinityProfile">
            <Box className="productAffinityImg">
              <Skeleton
                variant="rectangular"
                className="skeletonAvatar"
                active
              />
            </Box>
            <Box className="productAffinityNameSection">
              <Typography>
                <Skeleton className="skeletonSubHeading" active />
              </Typography>
              <Typography>
                <Skeleton className="skeletonText" active />
              </Typography>
            </Box>
          </Box>
          <Box className="productAffinityRightSide">
            <Box className="progressBarSection">
              <BorderLinearProgress variant="determinate" value={0} />
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box className="progressBarPriceSection">
                  <Typography variant="subtitle2">
                    <Skeleton className="skeletonSubHeading" active />
                  </Typography>
                </Box>
                <Box className="progressBarPriceSection">
                  <Typography variant="subtitle2">
                    <Skeleton className="skeletonSubHeading" active />
                  </Typography>
                </Box>
                <Box className="progressBarPriceSection">
                  <Typography variant="subtitle2">
                    <Skeleton className="skeletonSubHeading" active />
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="percentageSection">
              <Skeleton className="skeletonValue" active />
            </Box>
          </Box>
        </Box>
      );
    }

    return results;
  };

  return (
    <Box className="productAffinity">
      <Box className="cardHeader">
        <Typography variant="h6" className="blue">
          Product Affinity
        </Typography>
        <MoreHorizOutlinedIcon sx={{ color: "black", marginLeft: "auto" }} />
      </Box>
      {loading
        ? skeletonRows()
        : currentItems?.map((product) => (
            <Box className="productAffinityBody">
              <Box className="productAffinityProfile">
                <Box className="productAffinityImg">
                  <img src={CardSeatImg} alt="img" />
                </Box>
                <Box className="productAffinityNameSection">
                  <Typography>{product.name}</Typography>
                  <Typography>{product.description}</Typography>
                </Box>
              </Box>
              <Box className="productAffinityRightSide">
                <Box className="progressBarSection">
                  <BorderLinearProgress variant="determinate" value={50} />
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box className="progressBarPriceSection">
                      <Typography variant="subtitle2">
                        ${formatAmount(product.price)}
                      </Typography>
                      <Typography>Price</Typography>
                    </Box>
                    <Box className="progressBarPriceSection">
                      <Typography variant="subtitle2">
                        ${formatAmount(product.price)}
                      </Typography>
                      <Typography>Price</Typography>
                    </Box>
                    <Box className="progressBarPriceSection">
                      <Typography variant="subtitle2">
                        ${formatAmount(product.price)}
                      </Typography>
                      <Typography>Price</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="percentageSection">
                  <Typography>{formatAmount(product.progressBarValue)}%</Typography>
                </Box>
              </Box>
            </Box>
          ))}
      <Box className="cardFooter">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ marginLeft: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default ProductAffinity;
