import React from "react";
import { useMediaQuery } from "react-responsive";
import { Box, Grid } from "@mui/material";
import StatCard from "./StatCard";
import ChartCard from "./SegmentsTab/ChartCard";
import BioCard from "./SegmentsTab/BioCard";
import Images from "../../assets/img/ImgConstants";
import JourneyCard from "./SegmentsTab/JourneyCard";
import MetricsCard from "./SegmentsTab/MetricCards/MetricsCard";
import ProductAffinity from "./SegmentsTab/ProductAffinity";
import TopSellersCard from "./SegmentsTab/TopSellersCard";
import { Carousel } from "antd";
import "./style.scss";

const connections = [
  ["Google Ads", Images.GOOGLE_ADS_ICON, Images.GOOGLE_ADS_ICON_DISABLED, false],
  ["Facebook", Images.FACEBOOK_ICON, Images.FACEBOOK_ICON_DISABLED, false],
  ["Check-In", Images.CHECKIN_ICON, Images.CHECKIN_ICON_DISABLED, true],
  ["Ticketing", Images.TICKETING_ICON, Images.TICKETING_ICON_DISABLED, false],
];
const services = [
  ["Online", Images.ONLINE_ICON, Images.ONLINE_ICON_DISABLED, true],
  ["In-Store", Images.INSTORE_ICON, Images.INSTORE_ICON_DISABLED, true],
  ["Social", Images.SOCIAL_ICON, Images.SOCIAL_ICON_DISABLED, false],
  [
    "Attribution",
    Images.ATTRIBUTION_ICON,
    Images.ATTRIBUTION_ICON_DISABLED,
    false,
  ],
];
const CustomerInsights = (props) => {
  const { insightsData, isLoading } = props;
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });

  const statCards = [
    {
      title: "Customer LTV",
      amount: insightsData?.customerLTV?.amount,
      percent: insightsData?.customerLTV?.percentage,
      extra: insightsData?.customerLTV?.extraAmount,
      color: "greenBadge",
      icon: Images.CUSTOMER_LTV_ICON,
      arrow: Images.GREEN_UP_ARROW,
      showInfo: true,
      isLoading: isLoading,
    },
    {
      title: "Upsell",
      amount: insightsData?.upSell?.amount,
      percent: insightsData?.upSell?.percentage,
      extra: insightsData?.upSell?.extraAmount,
      color: "orangeBadge",
      icon: Images.UP_SELL_ICON,
      arrow: Images.YELLOW_STRAIGHT_ARROW,
      showInfo: false,
      isLoading: isLoading,
    },
    {
      title: "Cross-Sell",
      amount: insightsData?.crossSell?.amount,
      percent: insightsData?.crossSell?.percentage,
      extra: insightsData?.crossSell?.extraAmount,
      color: "redBadge",
      icon: Images.CROSS_SELL_ICON,
      arrow: Images.RED_DOWN_ARROW,
      showInfo: false,
      isLoading: isLoading,
    },
  ];

  const metricCards = [
    {
      title: "Ratings",
      rating: insightsData?.ratingMatrics?.avgStarRating,
      totalRating: insightsData?.ratingMatrics?.totalRating,
      icon: Images.STARS_ICON,
      users: insightsData?.ratingMatrics?.totalUsers,
      isLoading: isLoading,
      isRatingsCard: true,
      isPercentage: false,
    },
    {
      title: "CLTV",
      amount: insightsData?.cltv?.amount,
      percent: insightsData?.cltv?.percentage,
      extra: "55,000",
      color: "greenBadge",
      icon: Images.DOLLER_PERSON_ICON,
      arrow: Images.GREEN_UP_ARROW,
      isLoading: isLoading,
    },
    {
      title: "Sales Lift",
      amount: insightsData?.salesLift?.amount,
      percent: insightsData?.salesLift?.percentage,
      extra: "55,000",
      color: "redBadge",
      icon: Images.SALES_ICON,
      arrow: Images.RED_DOWN_ARROW,
      isLoading: isLoading,
    },
    {
      title: "Average Order Value",
      amount: insightsData?.averageOrderValue?.amount,
      icon: Images.GIFT_ICON,
      isPercentage: false,
      isLoading: isLoading,
    },
    {
      title: "Customer Acquisition Cost",
      amount: insightsData?.customerAcquisitionCost?.amount,
      percent: insightsData?.customerAcquisitionCost?.percentage,
      extra: insightsData?.customerAcquisitionCost?.extraAmount,
      color: "orangeBadge",
      icon: Images.DOLLER_BAG_ICON,
      arrow: Images.YELLOW_STRAIGHT_ARROW,
      isLoading: isLoading,
    },
    {
      title: "Timing",
      amount: insightsData?.timing,
      icon: Images.SUN_ICON,
      isPercentage: false,
      isToggle: true,
      isTime: true,
      isLoading: isLoading,
    },
  ];

  return (
    <Box style={{ marginBottom: "40px" }}>
      <Grid container spacing={3} sx={{ marginBottom: "20px" }}>
        <Grid item xl={6} lg={6} md={12} xs={12}>
          <BioCard
            name={insightsData?.segmentBio?.name}
            image={insightsData?.segmentBio?.img}
            rating={insightsData?.segmentBio?.rating}
            email={insightsData?.segmentBio?.email}
            phone={insightsData?.segmentBio?.phone}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={12} xs={12}>
          <ChartCard
            salesAmount={insightsData?.salesChart?.amount}
            chartData={
              insightsData?.chart?.length > 0 ? insightsData?.chart : []
            }
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ marginBottom: "20px", overflowX: isLoading && "clip" }}
      >
        {isMobile ? (
          <Grid item sm={12} xs={12}>
            <Box className="customer-insights sliderBox">
              <Carousel
                autoplay
                infinite={true}
                slidesToShow={1}
                slidesToScroll={1}
                dots={true}
                arrows={false}
                variableWidth={true}
              >
                {statCards.map((card, index) => (
                  <StatCard
                    key={index}
                    title={card?.title}
                    amount={card?.amount}
                    percent={card?.percent}
                    extra={card?.extra}
                    color={card?.color}
                    icon={card?.icon}
                    arrow={card?.arrow}
                    showInfo={card?.showInfo}
                    isLoading={card?.isLoading}
                  />
                ))}
              </Carousel>
            </Box>
          </Grid>
        ) : (
          statCards.map((card, index) => (
            <Grid item xl={4} lg={6} md={6} xs={12}>
              <StatCard
                key={index}
                title={card?.title}
                amount={card?.amount}
                percent={card?.percent}
                extra={card?.extra}
                color={card?.color}
                icon={card?.icon}
                arrow={card?.arrow}
                showInfo={card?.showInfo}
                isLoading={card?.isLoading}
              />
            </Grid>
          ))
        )}
      </Grid>
      <Grid container spacing={3} sx={{ overflowX: isLoading && "clip" }}>
        <Grid item xs={12}>
          <JourneyCard
            connections={connections}
            services={services}
            campaignNumber={"0122"}
          />
        </Grid>
        {isMobile ? (
          <Grid item sm={12} xs={12}>
            <Box className="customer-insights sliderBox">
              <Carousel
                autoplay
                // speed={5000}
                // waitForAnimate={true}
                infinite={true}
                slidesToShow={1}
                slidesToScroll={1}
                dots={true}
                arrows={false}
                variableWidth={true}
              >
                {metricCards.map((card, index) => (
                  <MetricsCard
                    title={card?.title}
                    rating={card?.rating}
                    totalRating={card?.totalRating}
                    amount={card?.amount}
                    percent={card?.percent}
                    extra={card?.extra}
                    color={card?.color}
                    icon={card?.icon}
                    arrow={card?.arrow}
                    users={card?.users}
                    isLoading={card?.isLoading}
                    isRatingsCard={card?.isRatingsCard}
                    isPercentage={card?.isPercentage}
                    isToggle={card?.isToggle}
                    isTime={card?.isTime}
                  />
                ))}
              </Carousel>
            </Box>
          </Grid>
        ) : (
          metricCards.map((card, index) => (
            <Grid key={index} item xl={4} lg={6} md={6} xs={12}>
              <MetricsCard
                title={card?.title}
                rating={card?.rating}
                totalRating={card?.totalRating}
                amount={card?.amount}
                percent={card?.percent}
                extra={card?.extra}
                color={card?.color}
                icon={card?.icon}
                arrow={card?.arrow}
                users={card?.users}
                isLoading={card?.isLoading}
                isRatingsCard={card?.isRatingsCard}
                isPercentage={card?.isPercentage}
                isToggle={card?.isToggle}
                isTime={card?.isTime}
              />
            </Grid>
          ))
        )}
        <Grid item xl={6} lg={12} xs={12}>
          <ProductAffinity
            productAffinity={
              insightsData?.productAffinity?.length > 0
                ? insightsData?.productAffinity
                : []
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xl={6} lg={12} xs={12}>
          <TopSellersCard
            topSellers={
              insightsData?.topSellers?.length > 0
                ? insightsData?.topSellers
                : []
            }
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerInsights;
