import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Images from "../../../../assets/img/ImgConstants";
import "./style.scss";

const Banner = () => {
  return (
    <Box className="banner">
      <Typography variant="h6" className="bannerText">
        Unlock <span className="highlighted-text">AI personalization</span>{" "}
        capbilities with a paid plan
      </Typography>
      <Button
        variant="contained"
        className="bannerButton"
        endIcon={Images.REDIRECT_ICON}
      >
        Upgrade
      </Button>
    </Box>
  );
};

export default Banner;
