import { LOGOUT_REQUEST_SUCCESS,CLEAR_STORE } from "../constant/auth";
import {
  OPT_OUT_REQUEST,
  OPT_OUT_REQUEST_SUCCESS,
  OPT_OUT_REQUEST_ERROR,
} from "../constant/opt-out";

const initialState = {
  // Initial state values go here
  loading: false,
  optOut: null,
  error: null,
};

export default function OptOut(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STORE:
      return initialState;

    case OPT_OUT_REQUEST:
      return {
        ...state,
        loading: true,
        optOut: false,
      };
    case OPT_OUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        optOut: true,
      };
    case OPT_OUT_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        optOut: false,
      };
    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        optOut: null,
        error: null,
      };
    default:
      return state;
  }
}
