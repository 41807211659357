/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { PhoneInput, PhoneInputFormItem } from "@validate-phone/antd-input";

import Footer from "../../components/Layout/Footer";
import CheckInLogo from "../../assets/img/check-in-logo.svg";

import "./style.scss";
import { Col, Form, Input, Row, Spin, Button, Checkbox } from "antd";
import { createCheckIn } from "../../redux/actions/check-in";

const regexPhone = new RegExp(
  "^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$"
);

const CheckIn = () => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { loading, error, checkin } = useSelector((state) => state.checkIn);
  console.log("checkin: ", checkin);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    acceptedTerms: false,
    allowedShareData: false,
  });

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    acceptedTerms,
    allowedShareData,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onCheckBoxesChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.checked });

  useEffect(() => {
    if (checkin) {
      form.resetFields();
    }

    return () => {
      form.resetFields();
    };
  }, [checkin]);

  const handleSubmit = (values) => {
    if (!values.allowedShareData) {
      values.allowedShareData = false;
    }
    dispatch(createCheckIn(values));
    setFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      acceptedTerms: false,
      allowedShareData: false,
    });
    form.resetFields();
  };

  return (
    // <Spin tip="Signing..." size="large">
    <div className="checkIn">
      <div
        className={`checkIn-main${isMobile ? " mobile" : ""}${
          isTablet ? " tablet" : ""
        }`}
      >
        <div className="checkIn-main-left">
          <div className="checkIn-main-left-header">
            <div className="checkIn-main-left-header-logo">
              <img src={CheckInLogo} alt="Logo" />
            </div>
            <h1 className="checkIn-main-left-header-title">Check in</h1>
            <p className="checkIn-main-left-header-subtitle">
              Please enter your information below
            </p>
          </div>
          <div className="checkIn-main-left-main">
            <Form
              form={form}
              layout="vertical"
              className="checkIn-form"
              onFinish={handleSubmit}
            >
              <Row gutter={[24, 0]}>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <Form.Item name="firstName" label="First name">
                    <Input placeholder="John" size="large" />
                  </Form.Item>

                  {/* <div className="input-group">
                    <label className="form-label" htmlFor="firstName">
                      First name
                    </label>
                    <input
                      className="form-input"
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="John"
                      value={firstName}
                      onChange={onChange}
                      required
                    />
                  </div> */}
                </Col>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <Form.Item name="lastName" label="Last name">
                    <Input placeholder="Doe" size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone number"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Enter your phone number",
                      },
                      {
                        pattern:
                          /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
                        message: "Phone number invalid",
                      },
                      {
                        min: 10,
                        message: "Enter 10 digits",
                      },
                      {
                        max: 12,
                        message: "Max 12 digits",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter 10 digits, no lines"
                      size="large"
                    />
                    {/* <PhoneInputFormItem
                      name="phone"
                      label="Phone number"
                      errorMessage="Please provide a valid phone number"
                      hasFeedback
                    >
                      <PhoneInput />
                    </PhoneInputFormItem> */}
                  </Form.Item>

                  {/* <div className="input-group">
                    <label className="form-label" htmlFor="phoneNumber">
                      Phone number
                    </label>
                    <input
                      className="form-input"
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter 10 digits, no lines"
                      value={phoneNumber}
                      onChange={onChange}
                      // required
                    />
                  </div> */}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label="Email"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Enter your email",
                      },
                      {
                        type: "email",
                        message: "Enter a valid email address",
                      },
                    ]}
                  >
                    <Input placeholder="Email" size="large" />
                  </Form.Item>

                  {/* <div className="input-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="form-input"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="email"
                      value={email}
                      onChange={onChange}
                      required
                    />
                  </div> */}
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="acceptedTerms"
                    valuePropName="checked"
                    hasFeedback
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Please agree before continuing")
                              ),
                      },
                    ]}
                  >
                    <Checkbox>
                      Accept{" "}
                      <a href="#" id="acceptedTerms">
                        terms and conditions
                      </a>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="allowedShareData"
                    valuePropName="checked"
                    // rules={[
                    //   {
                    //     validator: (_, value) =>
                    //       value
                    //         ? Promise.resolve()
                    //         : Promise.reject(
                    //             new Error("Please agree before continuing")
                    //           ),
                    //   },
                    // ]}
                  >
                    <Checkbox>
                      Agree to share data with partners for improved{" "}
                      <a href="#" id="allowedShareData">
                        customer experience and personalized offers.
                      </a>
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={24}></Col>
              </Row>

              {/* <Row>
                <Col span={24}>
                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="acceptedTerms"
                      name="acceptedTerms"
                      checked={acceptedTerms}
                      onChange={onCheckBoxesChange}
                      required
                    />
                    <label
                      className="input-checkbox-label"
                      htmlFor="acceptedTerms"
                    >
                      Accept{" "}
                      <a href="#" id="acceptedTerms">
                        terms and conditions
                      </a>
                    </label>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="allowedShareData"
                      name="allowedShareData"
                      checked={allowedShareData}
                      onChange={onCheckBoxesChange}
                    />
                    <label
                      className="input-checkbox-label"
                      htmlFor="allowedShareData"
                    >
                      Agree to share data with partners for improved{" "}
                      <a className="">
                        customer experience and personalized offers.
                      </a>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col span={24}>
                  {/* <button type="submit" className="checkInBtn">
                    Check-in
                  </button> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="checkInBtn"
                    block
                    loading={loading}
                  >
                    Check-in
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className="checkIn-main-right">
          <div className="checkIn-main-right-overide"></div>
        </div>
      </div>
      <Footer />
    </div>
    // </Spin>
  );
};

export default CheckIn;

//  Your username is Kata1 and temporary password is 8ENr6fD?.
