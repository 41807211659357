/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./style.scss";
import { Rate, Input, Button, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createRating } from "../../../redux/actions/co-pliot";
import moment from "moment";
import { toast } from "react-toastify";

const RateAndReviews = ({ userRating, customer_id, onClose, onView, view }) => {
  const dispatch = useDispatch();
  const { rated } = useSelector((state) => state.coPilot);

  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState("");

  const handleSubmitRating = () => {
    if (rate > 0 && customer_id) {
      dispatch(
        createRating({
          customer_id: customer_id,
          rating: rate,
          comments: comment,
          submitted_at: moment().format("YYYY-MM-DD"),
        })
      );
    }
  };

  useEffect(() => {
    if (rated) {
      toast.success("Submitted ratings.");
      setRate(0);
      setComment("");
      onClose();
    }
  }, [rated]);

  return (
    <div className="rateAndReviews">
      <h1>
        {(userRating &&
          userRating.customer_rating_average &&
          Math.round(userRating.customer_rating_average * 10) / 10) ||
          0}
      </h1>
      <Rate
        allowHalf
        value={
          (userRating &&
            userRating.customer_rating_average &&
            Math.round(userRating.customer_rating_average * 10) / 10) ||
          0
        }
        disabled
      />
      <h6>
        Based on{" "}
        <a
          className="viewReviews"
          onClick={() => {
            onView("reviews");
          }}
        >
          {(userRating && userRating.customer_rating_count) || 0} reviews
        </a>
      </h6>

      {view === "reviews" ? (
        <div className="listReviews">
          {userRating &&
          userRating.all_reviews &&
          userRating.all_reviews.length > 0 ? (
            userRating.all_reviews.map((item, i) => (
              <div className="reviewItem" key={i}>
                <div className="itemHead">
                  <Rate allowHalf value={Number(item.rating)} disabled />
                  <h5>{item.submitted_by || ""}</h5>
                  <p>
                    {item.submitted_at
                      ? moment
                          .utc(item.submitted_at)
                          .local()
                          .format("MMM DD, YYYY")
                      : ""}
                  </p>
                </div>
                <p className="comment">{item.comment || ""}</p>
                <a className="readMore">Read more</a>
              </div>
            ))
          ) : (
            <Empty />
          )}
        </div>
      ) : (
        <div className="rateForm">
          <h5>Rate customer</h5>
          <p>Would you do business with this customer again?</p>
          <Rate allowHalf value={rate} onChange={(value) => setRate(value)} />

          <div className="comments">
            <label>Comments</label>
            <Input.TextArea
              rows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <Button
            type="primary"
            block
            className="btnSubmit"
            onClick={handleSubmitRating}
            disabled={rate === 0}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default RateAndReviews;
