import {
  Box,
  Button,
  Chip,
  Grid,
  Typography,
  Avatar,
  Skeleton,
  Item,
  Divider,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeedId, getRatingId, updatePersonas, getAllRecommendations } from "../../../redux/actions/dashboard";
import { useMediaQuery } from "react-responsive";
import Images from "../../../assets/img/ImgConstants";
import "../../../pages/Reports/style.scss";
import "./style.scss";
import CircleIcon from "@mui/icons-material/Circle";
import MailIcon from "@iconify-icons/ic/mail-outline";
import PhoneIcon from "@iconify-icons/bx/bxs-phone";
import { Icon } from "@iconify/react";
import { Carousel } from "antd";
import woman from "../../../assets/img/woman.png";
import AdjustIcon from "@mui/icons-material/Adjust";
import SimpleDialog from "./SimpleDialog";
import RatingDialog from "./Rating";
const BioCard = (props) => {
  const { name, image, isLoading } = props;
  const emails = ['username@gmail.com', 'user02@gmail.com'];
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [loading, setLoading] = useState(true);
  const [colorIndicator, setColorIndicator] = useState("");
  const [siteStatus, setSiteStatus] = useState();
  const [ratingById, setRatingById] = useState();
  const [personaType, setPersonaType] = useState();
  const [primaryPersona, setPrimaryPersona] = useState();
  const [secondaryPersona, setSecondaryPersona] = useState();
  const [primaryPersonabgColor, setPrimaryPersonabgColor]= useState()
  const [primaryPersonalabelColor, setPrimaryPersonalabelColor]= useState()
  const [secPersonabgColor, setSecPersonabgColor]= useState()
  const [secPersonalabelColor, setSecPersonalabelColor]= useState()
  const [userData, setUserData] = useState();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [ratingSelectedValue, setRatingSelectedValue] = useState("");
  const [ratingOpen, setRatingOpen] = useState(false)
  const dispatch = useDispatch();
  const { feedByID, feedsData, bioLoading, rating, ratingsData } = useSelector(
    (state) => state.dashboard
  );
  const personaColors = [
    {
        bgColor: "rgba(2, 175, 239, 0.2)",
        labelColor:"#02AFEF"
    },
    {
        bgColor: "rgba(125, 198, 153, 0.2)",
        labelColor:"#7DC699"
    },
    {
        bgColor: "rgba(254, 164, 46, 0.2)",
        labelColor:"#FEA42E"
    },
    {
        bgColor: "rgba(125, 0, 183, 0.2)",
        labelColor:"#7D00B7"
    },
    {
        bgColor: "rgba(188, 62, 199, 0.2)",
        labelColor:"#BC3EC7"
    },
    {
        bgColor: "rgba(233, 105, 105, 0.2)",
        labelColor:"#E96969"
    },
    {
        bgColor: "rgba(227, 0, 183, 0.2)",
        labelColor:"#E300B7"
    },
    {
        bgColor: "rgba(2, 175, 239, 0.2)",
        labelColor:"#02AFEF"
    },
    {
        bgColor: "rgba(125, 198, 153, 0.2)",
        labelColor:"#7DC699"
    },
    {
        bgColor: "rgba(254, 164, 46, 0.2)",
        labelColor:"#FEA42E"
    },
    {
        bgColor: "rgba(125, 0, 183, 0.2)",
        labelColor:"#7D00B7"
    },
    {
        bgColor: "rgba(188, 62, 199, 0.2)",
        labelColor:"#BC3EC7"
    },
    {
        bgColor: "rgba(233, 105, 105, 0.2)",
        labelColor:"#E96969"
    },
    {
        bgColor: "rgba(227, 0, 183, 0.2)",
        labelColor:"#E300B7"
    },
    {
        bgColor: "rgba(2, 175, 239, 0.2)",
        labelColor:"#02AFEF"
    },
    {
        bgColor: "rgba(125, 198, 153, 0.2)",
        labelColor:"#7DC699"
    },
    {
        bgColor: "rgba(254, 164, 46, 0.2)",
        labelColor:"#FEA42E"
    },
    {
        bgColor: "rgba(125, 0, 183, 0.2)",
        labelColor:"#7D00B7"
    },
    {
        bgColor: "rgba(188, 62, 199, 0.2)",
        labelColor:"#BC3EC7"
    },
    {
        bgColor: "rgba(233, 105, 105, 0.2)",
        labelColor:"#E96969"
    },
    {
        bgColor: "rgba(227, 0, 183, 0.2)",
        labelColor:"#E300B7"
    }, {
        bgColor: "rgba(2, 175, 239, 0.2)",
        labelColor:"#02AFEF"
    },
    {
        bgColor: "rgba(125, 198, 153, 0.2)",
        labelColor:"#7DC699"
    },
    {
        bgColor: "rgba(254, 164, 46, 0.2)",
        labelColor:"#FEA42E"
    },
    {
        bgColor: "rgba(125, 0, 183, 0.2)",
        labelColor:"#7D00B7"
    },
    {
        bgColor: "rgba(188, 62, 199, 0.2)",
        labelColor:"#BC3EC7"
    },
    {
        bgColor: "rgba(233, 105, 105, 0.2)",
        labelColor:"#E96969"
    },
    {
        bgColor: "rgba(227, 0, 183, 0.2)",
        labelColor:"#E300B7"
    },
]
  const handleClickOpen = (type) => {
    setPersonaType(type)
    setOpen(true);
  };
  const ratingHandleClickOpen = () =>{
    setRatingOpen(true);
  }
  

  const handleClose = (value) => {
    setOpen(false);
    if (personaType == "primary") {
      //userData.personaDetails.PrimaryPersona = value;
    }
    setSelectedValue(value);
  };
  const ratingHandleClose = (value) => {
    setRatingOpen(false);
    setRatingSelectedValue(value)
  }
  useEffect(() => {
    if (selectedValue) {
      if (personaType == "primary") {
        setPrimaryPersona(selectedValue.personaLabel);
        setPrimaryPersonabgColor(personaColors[selectedValue?.personaId ?selectedValue?.personaId :0].bgColor)
        setPrimaryPersonalabelColor(personaColors[selectedValue?.personaId ?selectedValue?.personaId :0].labelColor)
        //personaColors[selectedValue?.personaId ?selectedValue?.personaId :0].bgColor}`, 
        //color: `${personaColors[selectedValue?.personaId ?selectedValue?.personaId :0].labelColor}`
      }
      if (personaType == "secondary") {
        setSecondaryPersona(selectedValue.personaLabel);
        setSecPersonabgColor(personaColors[selectedValue?.personaId ?selectedValue?.personaId :0].bgColor)
        setSecPersonalabelColor(personaColors[selectedValue?.personaId ?selectedValue?.personaId :0].labelColor)

      }
      dispatch(updatePersonas(personaType, selectedValue?.personaId, feedByID?.customerId, feedByID?.databillityId))
    }
  }, [selectedValue]);

  
  const compareDates = (a, b) => {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (feedsData && !isMobile) {
      let data = feedsData.sort(compareDates);
      let bioId = data[0]?.id;
      if(bioId){
      dispatch(getFeedId(bioId));
      dispatch(getAllRecommendations(bioId));
      }
      else{
      setLoading(true);
      }
    }
  }, [feedsData]);

  useEffect(() => {
    if (feedByID) {
      setPrimaryPersona(feedByID?.personaDetails?.PrimaryPersona)
      setSecondaryPersona(feedByID?.personaDetails?.SecondaryPersona)
      setUserData(feedByID);

    }
    if (rating) {
      setRatingById(rating)
    }
  }, [feedByID, rating]);
  useEffect(() => {
    setLoading(bioLoading);
  }, [bioLoading]);
  useEffect(() => {
    if (userData) {
      statusIndicator(userData?.customerDetails?.checkin_time)
    }
  }, [userData])

  function changeTimeFormat(bioTime) {
    let date = new Date(bioTime);

    let formatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    let formattedTime = formatter.format(date);
    return formattedTime;
  }


  const statusIndicator = (checkinTime) => {
    var givenDate = new Date(checkinTime);
    var currentDate = new Date();
    var timeDifference = currentDate - givenDate;
    var hoursDifference = timeDifference / (1000 * 60 * 60);
    if (hoursDifference <= 1) {
      setColorIndicator("green");
      setSiteStatus("Onsite")
    } else if (hoursDifference > 1 && hoursDifference <= 2) {
      setColorIndicator("Yellow");
      setSiteStatus("Likely Onsite");
    } else {
      setColorIndicator("grey");
      setSiteStatus("Offsite");
    }
  }
  const skeletonCard = () => {
    return (
      <Box className="segmentBio" style={{ height: "inherit" }}>
        <Box className="bioUpperSection" style={{ backgroundColor: "white" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={10} md={11}>
                <Skeleton variant="rectangular" width={20} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row" justifyContent="center">
            <Skeleton variant="circular">
              <Avatar sx={{ display: "flex", width: 100, height: 100 }} />
            </Skeleton>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ marginTop: "2%", marginBottom: "30px" }}
          >
            <Grid item>
              <Grid component="span" item xs={9} spacing={1}>
                <Stack direction="row" alignItems="end" gap={1}>
                  <span style={{ fontSize: "large" }}>
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </span>
                </Stack>
                <Skeleton variant="rectangular" height={60} />
                <div></div>
                <Skeleton width={40} />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" /> <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={60} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };
  return (
    <>
      {loading ? (
        skeletonCard()
      ) : (
        <Box className="segmentBio" style={{ height: "inherit" }}>
          <Box className="bioUpperSection" style={{ backgroundColor: "white" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={10} md={10}>
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#002857",
                      fontWeight: "500",
                    }}
                  >
                    <CircleIcon
                      color={colorIndicator}
                      style={{
                        fontSize: "11px",
                        color: `${colorIndicator}`,
                        paddingRight: "4px",
                      }}
                    />
                    {/* On-Site */}
                    {siteStatus}

                  </span>
                </Grid>
                <Grid item xs={2} md={2} style={{paddingLeft: isMobile? "0px":"16px"}}>
                  <span
                    style={{
                      fontSize: "11px",
                      color: "#02AFEF",
                      fontWeight: "700",
                    }}
                  >
                    {userData?.customerDetails?.checkin_time
                      ? new Intl.DateTimeFormat().format(
                        new Date(userData?.customerDetails?.checkin_time)
                      )
                      : "Today"}
                  </span>
                  <div
                    style={{
                      fontSize: "11px",
                      color: "#002857",
                      fontWeight: "500",
                    }}
                  >
                    {changeTimeFormat(
                      userData?.customerDetails?.checkin_time ? userData?.customerDetails?.checkin_time : null
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container direction="row" justifyContent="center">
              <Avatar
                alt="Bio Image"
                src={woman}
                sx={{ display: "flex", width: 100, height: 100 }}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{ marginTop: "2%" }}
            >
              <Grid item>
                <Grid component="span" item xs={9} spacing={1}>
                  <Typography variant="body1" className="blue bold-700">
                    <Stack direction="row" alignItems="end" gap={1}>
                      <span style={{ fontSize: "large" }}>{userData?.customerDetails?.name}</span>
                      <Box component="span" className="ratingBox" style={{ fontSize: "11px", fontWeight: "100" }} onClick={() => ratingHandleClickOpen()}>
                        {Images.STAR_ICON}
                        <Typography
                          variant="body1"
                          className="darker-gray bold-700"
                          style={{ fontSize: "11px", fontWeight: "100" }}
                        >
                          {rating?.data?.customer_rating_average ? rating?.data?.customer_rating_average.toFixed(2) : "N/A"}

                        </Typography>
                      </Box>
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{ marginTop: "12px", color: "#6F7174" }}
            >
              <Grid component="span" item md={3} xs={7}>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex" }}>
                    <Icon icon={MailIcon} style={{ fontSize: "12px" }} />
                    <p
                      style={{
                        fontSize: "11px",
                        lineHeight: "11px",
                        paddingLeft: "4px",
                        fontWeight: "500",
                      }}
                    >
                      {userData?.customerDetails?.email}
                    </p>
                  </div>
                  <div style={{ display: "flex", marginLeft: "15px" }}>
                    <Icon icon={PhoneIcon} style={{ fontSize: "12px" }} />
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        fontSize: "11px",
                        lineHeight: "11px",
                        paddingLeft: "4px",
                        fontWeight: "500",
                      }}
                    >
                      778-999-1234
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 2 }}>
            <Grid
              container
              direction="row"
              justifyContent="start"
              style={{
                display: "flex",
                padding: "5%",
                paddingTop: "30px",
                paddingLeft: "0%",
                paddingRight: !isMobile? "11%": "0%",
                marginTop: "10px",
                marginLeft: !isMobile? "22%" :"6%"
                
              }}
            >
              <Grid item xs={4} sm={6} md={4} style={{minWidth: isMobile? "max-content": "0px"}}>
                <Typography
                  variant="body2"
                  className="blue bold-500"
                  style={{ paddingBottom: "7px", fontSize: "large" }}
                >
                  Persona
                </Typography>
                <SimpleDialog
                  selectedValue={selectedValue}
                  open={open}
                  onClose={handleClose}
                  onClick={() => handleClickOpen("primary")}
                />
                <RatingDialog
               selectedValue={ratingSelectedValue}
               open={ratingOpen}
               onClose={ratingHandleClose}
               userId={userData?.customerId}
               userName={userData?.customerDetails?.name}
               userEmail={userData?.customerDetails?.email}
               userRating = {rating?.data?.customer_rating_average ? rating?.data?.customer_rating_average.toFixed(2) : "N/A"}
                />
                <div>
                  <Chip
                    label={primaryPersona}
                    variant="filled"
                    className="segmentBioBlueBadge badge"
                    style={{ fontSize: "small" , backgroundColor:`${primaryPersonabgColor}`, color: `${primaryPersonalabelColor}` }}
                    onClick={() => handleClickOpen("primary")}
                  />
                </div>
                <div>
                  <Chip
                    label={secondaryPersona}
                    variant="filled"
                    className="segmentBioGreenBadge badge"
                    style={{ fontSize: "small", backgroundColor:`${secPersonabgColor}`, color: `${secPersonalabelColor}` }}
                    onClick={() => handleClickOpen("secondary")}
                  />
                </div>
              </Grid>
              <Grid item xs={2} md={2} style={{marginLeft: isMobile? "4%":"0%"}}>
                <Typography
                  variant="body2"
                  className="blue bold-500"
                  style={{ paddingBottom: "7px", fontSize: "large" }}
                >
                  LTV
                </Typography>
                <div>
                  <Typography
                    variant="body2"
                    className="bold-600"
                    style={{ color: "#002857" }}
                  >
                    $0
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} md={2}  style={{marginLeft: isMobile? "2%":"0%"}}>
                <Typography
                  variant="body2"
                  className="blue bold-500"
                  style={{ paddingBottom: "7px", fontSize: "large" }}
                >
                  Power
                </Typography>
                <Typography
                  variant="body2"
                  className="bold-600"
                  style={{ color: "#002857" }}
                >
                  100
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider
            direction="row"
            justifyContent="center"
            style={{
              justifyContent: "flex-end",
              display: "flex",
              width: isMobile ? "90%" : "53%",
              marginLeft: isMobile ? "5%" : "24%",
              marginRight: isMobile ? "5%" : "23%",
              marginBottom: "4%",
              border: "1.5px solid #999999",
            }}
          />

          <Box sx={{ flexGrow: 1 }}>
            <Grid container direction="row" justifyContent="center">
              <Grid
                item
                xs={11}
                md={6}
                style={{ maxWidth: isMobile ? "100%" : "58%" }}
              >
                <Box
                  className="customer-insights sliderBox"
                  style={{ marginBottom: "35px" }}
                >
                  <span
                    style={{
                      marginLeft: isMobile ? "4%" : "20px",
                      paddingTop: "20px",
                      color: "#002857",
                      fontWeight: "500",
                      marginBottom: "10px",
                      fontSize: "large",
                    }}
                  >
                    Preferences
                  </span>
                  <Carousel
                    infinite={true}
                    itemsToShow={1}
                    slidesToShow={1}
                    slidesToScroll={1}
                    dots={true}
                    arrows={false}
                    style={{ marginTop: "10px" }}
                  >
                    <div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference1}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference2}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference3}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference4}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference5}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference6}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference7}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference8}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference9}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference10}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference11}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <Chip
                          label={userData?.preferenceDetails?.Preference12}
                          variant="filled"
                          className={
                            isMobile
                              ? "segmentBioYellowBadge badge"
                              : "segmentBioBlueBadge badge"
                          }
                          style={{
                            fontSize: "small",
                            width: "-webkit-fill-available",
                            justifyContent: "unset",
                            paddingLeft: "4px",
                          }}
                          icon={
                            <AdjustIcon
                              className="adjustIconstyle"
                              style={{
                                fontSize: "small",
                                color: isMobile ? "#FEA42E" : "02afef",
                                paddingRight: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </Carousel>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid item sm={12} xs={12}>
            <Box className="customer-insights sliderBox"></Box>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default BioCard;
