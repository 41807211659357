import React, { useState, useEffect} from "react";
import "./style.scss";

import UserImg from "../../../assets/img/user1.png";

const AvatarStatus = ({ status }) => {
  const [value, setValue] = useState("offline");

  useEffect(() => {
    if (status) {
      let _value;
      switch (status) {
        case "Likely Onsite":
          _value = "away";
          break;
        case "Onsite":
          _value = "active";
          break;
        case "Offsite":
          _value = "offline";
          break;
        case "Likely Offsite":
          _value = "offline";
          break;

        default:
          break;
      }
      setValue(_value);
    }
  }, [status]);

  return (
    <div className="avatarStatus">
      <img alt="avatar" src={UserImg} />
      <span className={`status ${value}`} />
    </div>
  );
};

export default AvatarStatus;
