import { React, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  Switch,
  Box,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { getAllCutomerData } from "../../redux/actions/co-pliot";
import { styled } from "@mui/material/styles";
import { Skeleton } from "antd";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRecommendations
} from "../../redux/actions/dashboard";
import useWindowSize from "../../hooks/useWindowSize";
import ButtonRangePicker from "../../components/common/ButtonRangePicker";
import CustomerDetailDrawer from "../../components/common/CustomerDetailDrawer";
import DashboardTab from "../../components/DashboardTab";
const filterFormat = "YYYY-MM-DD";

const StyledTab = styled(Tab)({
  textTransform: "none",
  color: "#999999",
  fontSize: "16px",

  "&.Mui-selected": {
    color: "#999999",
  },
});

const Dashboard = () => {
  const dispatch = useDispatch();
  const { customer, loadingCustomer, loadingCustomers } = useSelector(
    (state) => state.coPilot
  );

  const { width } = useWindowSize();
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [filters, setFilters] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: "selection",
  });


 
  const handleFilter = (dates) => {
    if (dates.startDate && dates.endDate) {
      setLoadingFilter(true);
      dispatch(
        getAllCutomerData({
          start_date: moment(dates.startDate).format(filterFormat),
          end_date: moment(dates.endDate).format(filterFormat),
        })
      );
    }
  };
  const loadData = () => {
    if (filters) {
      dispatch(
        getAllCutomerData({
          start_date: moment(filters.startDate).format(filterFormat),
          end_date: moment(filters.endDate).format(filterFormat),
        })
      );
    } else {
      dispatch(getAllCutomerData());
    }
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="xl" style={isMobile? {padding:"0px"}: {}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                className="blue bold-600"
                sx={{ pt: isMobile ? 6 : 0 }}
              >
                Dashboard
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <CustomerDetailDrawer
                isOpen={isDrawerOpen}
                onClose={onClose}
                customer={customer}
                isMobile={isMobile}
                isLoading={loadingCustomer}
                onLoadData={loadData}
              />
              {width > 1024 ? (
                <div className="copilot-datetime">
                  {loading ? (
                    <Skeleton.Input
                      active
                      size="small"
                      style={{ width: "180px" }}
                    />
                  ) : (
                    <div>
                      <ButtonRangePicker
                        onFilter={handleFilter}
                        showIcon={false}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="showFilter">
                  <ButtonRangePicker showIcon={true} onFilter={handleFilter} />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <DashboardTab />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
