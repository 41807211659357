import {
  LOAD_USER_REQUEST,
  LOAD_USER_REQUEST_FAIL,
  LOAD_USER_REQUEST_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAIL,
  LOGIN_REQUEST_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_REQUEST_FAIL,
  LOGOUT_REQUEST_SUCCESS,
  CLEAR_STORE,
  LOAD_DATABILLITY_USER_REQUEST,
  LOAD_DATABILLITY_USER_REQUEST_SUCCESS,
  LOAD_DATABILLITY_USER_REQUEST_FAIL,
  SEND_CODE,
  SEND_CODE_SUCCESS,
  SEND_CODE_FAIL,
  SAVE_CODE,
  SAVE_CODE_SUCCESS,
  SAVE_CODE_FAIL,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAIL,
} from "../constant/auth";
const initialState = {
  isAuthenticated: false,
  loading: false,
  error: null,
  email: null,
  token: localStorage.getItem("databillity_token"),
  refreshToken: null,
  user: null,
  databillity: null,
  newPassword: { code: null, password: null, reset: false},
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        newPassword: { code: null, password: null, reset: false },
        loading: true,
      };
    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: true,
        email: action.payload.email,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case LOGIN_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        email: null,
        token: localStorage.setItem("databillity_token", null),
        refreshToken: null,
        error: action.payload,
      };
    case LOGOUT_REQUEST_SUCCESS:
      console.log("logout success");
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: null,
        email: null,
        token: null,
        refreshToken: null,
        user: null,
        databillity: null,
        newPassword: { code: null, password: null, reset: false },
      };
    case CLEAR_STORE:
      return initialState;
    case LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload,
      };
    case LOAD_USER_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case LOAD_DATABILLITY_USER_REQUEST_SUCCESS:
      return {
        ...state,
        databillity: action.payload,
        error: null,
      };
    case LOAD_DATABILLITY_USER_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SEND_CODE:
      return {
        ...state,
        loading: true,
      };
    case SEND_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        email: action.payload,
      };
    case SEND_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SAVE_CODE:
      return {
        ...state,
        loading: true,
      };
    case SAVE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        newPassword: {
          ...state.newPassword,
          code: action.payload,
        },
      };
    case SAVE_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_NEW_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        email: null,
        newPassword: { code: null, password: null, reset: true },
        loading: false,
        error: null,
      };
    case SET_NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
