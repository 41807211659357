import { combineReducers } from 'redux'
import auth from './auth.js'
import coPilot from './co-pilot.js'
import notifications from './notification.js'
import connections from './connection.js'
import checkIn from './check-in.js'
import optOut from './opt-out.js'
import insights from './insights.js'
import onboarding from './onboarding.js'
import dashboard from './dashboard.js';

export default combineReducers({
    notifications,
    auth,
    coPilot,
    connections,
    checkIn,
    optOut,
    insights,
    onboarding,
    dashboard
})
