import { React, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  Switch,
  Box,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { Skeleton } from "antd";
import SegmentsTab from "../../components/Reports/SegmentsTab";
import IndividualsTab from "../../components/Reports/IndividualsTab";
import CustomerDetailDrawer from "../../components/common/CustomerDetailDrawer";
import { getAllCutomerData } from "../../redux/actions/co-pliot";
import "./style.scss";
import useWindowSize from "../../hooks/useWindowSize";
import ButtonRangePicker from "../../components/common/ButtonRangePicker";

const filterFormat = "YYYY-MM-DD";

const StyledTab = styled(Tab)({
  textTransform: "none",
  color: "#999999",
  fontSize: "16px",

  "&.Mui-selected": {
    color: "#999999",
  },
});

const Reports = () => {
  const dispatch = useDispatch();

  const { customer, loadingCustomer, loadingCustomers } = useSelector(
    (state) => state.coPilot
  );
  const { width } = useWindowSize();
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [filters, setFilters] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: "selection",
  });

  const handleFilter = (dates) => {
    if (dates.startDate && dates.endDate) {
      setLoadingFilter(true);
      dispatch(
        getAllCutomerData({
          start_date: moment(dates.startDate).format(filterFormat),
          end_date: moment(dates.endDate).format(filterFormat),
        })
      );
      setFilters({
        startDate: dates.startDate,
        endDate: dates.endDate,
        key: "selection",
      });
    }
  };

  const loadData = () => {
    if (filters) {
      dispatch(
        getAllCutomerData({
          start_date: moment(filters.startDate).format(filterFormat),
          end_date: moment(filters.endDate).format(filterFormat),
        })
      );
    } else {
      dispatch(getAllCutomerData());
    }
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    setStartDate(moment(filters.startDate).format(filterFormat));
    setEndDate(moment(filters.endDate).format(filterFormat));
  }, [filters]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                className="blue bold-600"
                sx={{ pt: isMobile ? 6 : 0 }}
              >
                Insights
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <CustomerDetailDrawer
                isOpen={isDrawerOpen}
                onClose={onClose}
                customer={customer}
                isMobile={isMobile}
                isLoading={loadingCustomer}
                onLoadData={loadData}
              />
              {width > 1024 ? (
                <div className="copilot-datetime">
                  {loading ? (
                    <Skeleton.Input
                      active
                      size="small"
                      style={{ width: "180px" }}
                    />
                  ) : (
                    <div>
                      <ButtonRangePicker
                        onFilter={handleFilter}
                        showIcon={false}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="showFilter">
                  <ButtonRangePicker showIcon={true} onFilter={handleFilter} />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={12} xs={12}>
          {isMobile ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px"
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#002857"}}
              >
                Segments
              </Typography>
              <Switch
                onChange={() => setActiveTab(activeTab === 0 ? 1 : 0)}
                sx={{
                  padding: "7px",
                  color: "primary",
                  "& .MuiSwitch-switchBase": {
                    transitionDuration: "800ms",
                  },
                  "& .MuiSwitch-track": {
                    borderRadius: 22 / 2,
                    backgroundColor: "#002857",
                    opacity: 1,
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#002857" }}
              >
                Individuals
              </Typography>
            </Box>
          ) : (
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
            >
              <StyledTab label="Segments" />
              <StyledTab label="Individuals" />
            </Tabs>
          )}
        </Grid>
        <Grid item xs={12}>
          {activeTab === 0 ? (
            <SegmentsTab startDate={startDate} endDate={endDate} />     //filters.startDate
          ) : activeTab === 1 ? (
            <IndividualsTab startDate={startDate} endDate={endDate} />   //filters.endDate
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Reports;
