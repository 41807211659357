import React, { useState } from "react";
import { Avatar, Button } from "antd";
import ModalAvatar from "./ModalAvatar";
import "./user-detail.scss";
import PhotoImg from "../../../assets/img/photo.svg";

const UserDetail = ({ name, image, type, isModal }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="userDetail">
        <div className="viewAvatar">
          {image ? (
            <Avatar size={50} src={image} />
          ) : (
            <div className="defaulltPhoto">
              <img alt="" src={PhotoImg} />
            </div>
          )}

          <Button className="btnEdit" onClick={() => setOpen(true)}>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.83752 2.24552C10.0542 2.02889 10.0542 1.66782 9.83752 1.4623L8.5377 0.162477C8.33218 -0.0541591 7.97112 -0.0541591 7.75448 0.162477L6.7324 1.179L8.81544 3.26205M0 7.91696V10H2.08304L8.22664 3.85085L6.14359 1.76781L0 7.91696Z"
                fill="#707070"
              />
            </svg>
          </Button>
        </div>
        <h3 className={`name ${isModal ? "modalName" : ""}`}>{name}</h3>
      </div>
      <ModalAvatar open={open} onClose={() => setOpen(false)} type={type} />
    </div>
  );
};

export default UserDetail;
