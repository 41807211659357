import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Modal, Box, Typography, Button } from "@mui/material";
import WarningIcon from "../../../assets/img/warning-icon.svg";
import { modalStyle } from "../../../pages/Onboarding/constants.js";
import { getBasicInfo } from "../../../redux/actions/onboarding.js";
import { scrollToTop } from "../../../utils/utilities.js";

const CancelModal = (props) => {
  const { open, setOpen } = props;
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const dispatch = useDispatch();
  const { onboarding } = useSelector(
    (state) => state.onboarding
  );

  const handleClose = () => setOpen(false);
  const handleBackButton = () => {
    if (onboarding.onboardingStep === 1) {
      dispatch(getBasicInfo(onboarding.account.email));
    }
    onboarding.onboardingStep = onboarding.onboardingStep - 1;
    scrollToTop();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          width: isMobile ? "70%" : isTablet ? "50%" : "30%",
        }}
      >
        <img src={WarningIcon} alt="Warning" />
        <Typography variant="h6" sx={{ fontWeight: "500", mt: 4 }}>
          Are you sure you want to cancel?
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 8 }}>
          If you cancel, we will not be able to save your data
        </Typography>
        <Button
          variant="contained"
          onClick={handleBackButton}
          sx={{ borderRadius: 5, width: "150px", mb: 3 }}
        >
          Yes
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{ borderRadius: 5, width: "150px" }}
        >
          No
        </Button>
      </Box>
    </Modal>
  );
};

export default CancelModal;
