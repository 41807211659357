import React from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

import Footer from "../../components/Layout/Footer";
import OptOutImg from "../../assets/img/opt-out.png";

const OptOutConfirmPage = () => {
  const navigate = useNavigate();

  const handleConfirmOptOut = () => {
    navigate("/");
  };

  return (
    <div className="optOutConfirm">
      <div className="optOutConfirm-content">
        <div className="optOutConfirm-content-detail">
          <img alt="opt-out" src={OptOutImg} />
          <h4 className="optOut-content-detail-title">
            You’ve been disconnected from the network
          </h4>
          <p className="optOutConfirm-content-detail-text">
            Your information will no longer be shared with third-party partners
            for personalized offers.
          </p>
          <Button
            className="optOutConfirm-content-detail-button"
            onClick={handleConfirmOptOut}
          >
            You can close this window
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OptOutConfirmPage;
