import React from "react";
import "./style.scss";
import { Skeleton, Table } from "antd";

const SkeletonTable = ({
  children,
  loading,
  active = true,
  rowCount = 5,
  columns,
}) => {
  if (loading) {
    return (
      <Table
        rowKey="key"
        pagination={false}
        dataSource={[...Array(rowCount)].map((_, index) => ({
          key: `key${index}`,
        }))}
        columns={columns.map((column) => {
          return {
            ...column,
            render: function renderPlaceholder() {
              return (
                <Skeleton
                  className="skeletonTable"
                  key={column.key}
                  title
                  round={true}
                  active={active}
                  paragraph={false}
                  style={{ margin: 0 }}
                />
              );
            },
          };
        })}
      />
    );
  } else {
    return <>{children}</>;
  }
};

export default SkeletonTable;
