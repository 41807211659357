import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import wheel from "../../assets/img/wheel.png";
import { useDispatch, useSelector } from "react-redux";
import CustomerDetaiilTab from "./CustomerDetailView";
import FeedCardMobileView from "./FeedCardMobileView";

const DashboardTab = () => {
  const [recommendData, setRecommendData] = useState();
  const [segmentsData, setSegmentsData] = useState();

  const dispatch = useDispatch();
  const { insightsData, isLoading } = useSelector((state) => state.insights);
  const { recommendationData } = useSelector((state) => state.dashboard);
  let description =
    "26 step hand wash service covering all important areas of the vehicle. Thorough vacuum and compressed air blow out of the entire vehicle.";
  let cardImage = wheel;
 

  useEffect(() => {
    if (!isLoading) {
      setSegmentsData(insightsData);
    }
  }, [insightsData]);
  useEffect(() => {
    if (recommendationData) {
      setRecommendData(recommendationData);
    }
  }, [recommendationData]);

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  return (
    // <CustomerDetaiilTab/>
    <React.Fragment>
      {" "}
      {isMobile ? <FeedCardMobileView />:<CustomerDetaiilTab />}
    </React.Fragment>
  );
};

export default DashboardTab;
