import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Layout from "./components/Layout";
import Notification from "./components/common/Notification";
import PrivateRoute from "./components/routing/privateRoute";

import Location from "./pages/Location";
import CoPilot from "./pages/CoPilot";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Connections from "./pages/Connections";
import OldConnections from "./pages/Connections-Old";
import Reports from "./pages/Reports";
import Settings from "./pages/Settings";
import CheckIn from "./pages/CheckIn";
import OptOut from "./pages/OptOut";
import OptOutConfirmPage from "./pages/OptOutConfirm";

import setAuthToken from "./utils/setAuthToken";
import store from "./redux/store";
import { loadUser, logout } from "./redux/actions/auth";
import "./App.scss";
import NotFound from "./pages/NotFound";
import { theme } from "./styles/theme";
import { ThemeProvider } from "@mui/material";
import Onboarding from "./pages/Onboarding";
import CreateAccountPage from "./pages/Onboarding/CreateAccountPage";
import { useSelector } from "react-redux";

function App() {
  const { onboarding } = useSelector(
    (state) => state.onboarding
  );
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API

    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch(logout());
    });
  }, []);

  // const isAuthenticated = Object.keys(localStorage).some((key) => key.includes("CognitoIdentityServiceProvider"))

  const urlParams = new URLSearchParams(window.location.search);
  const isAuthenticated = !!urlParams.get('code') || onboarding.account.verified;

  console.log('isAuthenticated', isAuthenticated)

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Notification />
        <Routes>
            <Route path="/create-account" element={<CreateAccountPage />} />
            <Route path="login" element={<Login />} />
            <Route path="opt-out" element={<OptOut />} />
            <Route path="opt-out/confirm" element={<OptOutConfirmPage />} />

            <Route path="/onboarding" element={isAuthenticated ? <Onboarding /> :  <Navigate to={"/login"} replace />} />
            
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/" element={<PrivateRoute component={Layout} />}>
              <Route path="/co-pilot" element={<CoPilot />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/location" element={<Location />} />
              <Route path="/connections-old" element={<OldConnections />} />
              <Route path="/connections" element={<Connections />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/settings" element={<Settings />} />
            </Route>
          <Route path="/check-in" element={<PrivateRoute component={CheckIn} />}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
